<template>
  <div class="user-cell-time-ago users-cmn__cell">
    <template v-if="date && !isNullDate(date)">
      <time-ago
        class="user-cell-time-ago__link users-cmn__str
          users-cmn__str_nb users-cmn__str_sec
        "
        :title="formatDateTimeRelative(date)"
        :date="date"
      />
    </template>

    <template v-else>
      <span class="users-cmn__str users-cmn__str_sec">
        {{ $t('COMMON.SYMBOLS.MDASH') }}
      </span>
    </template>
  </div>
</template>

<script>
import TimeAgo from 'Common/TimeAgo'
import { formatDateTimeRelative, isNullDate } from 'Utils/dateHelpers'

export default {
  name: 'user-cell-time-ago',

  components: {
    TimeAgo,
  },

  props: {
    date: {
      type: [Date, Number],
      default: 0,
    },
  },

  methods: {
    formatDateTimeRelative,
    isNullDate,
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/users.scss";
</style>
