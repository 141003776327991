import { UserCommand } from './UserCommand'
import { sdk } from 'Services/shelfNetworkSdk'

/**
 * @typedef {object} UpdateUserArgsDef
 * @property {object} attributes New attributes to apply to the user
 *
 * @typedef {import('./UserCommand').UserCommandArgs} UserCommandArgs
 * @typedef {UserCommandArgs & UpdateUserArgsDef} UpdateUserArgs
 */

export class UpdateUser extends UserCommand {
  /**
   * Create a configured UpdateUser command
   * @param {UpdateUserArgs} opts
   */
  constructor (opts) {
    super(opts)

    this._attributes = opts.attributes || {}
    this._snapshot = {}
  }

  async execute () {
    try {
      this._snapshot = this._prepareSnapshot()

      await sdk.horizon.account.update(this._attributes, this._userEntry.id)
      this._updateStoredItem(this._attributes)

      const msg = this.$t('CMD.USERS.UPDATE.SUCCESS_MSG', { name: this._getName() })
      this._reportSuccess(msg)
    } catch (error) {
      let msg
      if (error.httpStatus === 409) {
        if (this.isForcedErrorThrow) {
          this.isFailureReportSuppressed = true
        } else {
          msg = this.$t('CMD.USERS.UPDATE.FAILURE_CONFLICT_MSG')
        }
      }
      msg = msg || this.$t('CMD.USERS.UPDATE.FAILURE_MSG', { name: this._getName() })
      this._reportError(error, msg)
    }
  }

  async undo () {
    try {
      await sdk.horizon.account.update(this._snapshot, this._userEntry.id)
      this._updateStoredItem(this._snapshot)
    } catch (error) {
      this._reportUndoError(error)
    }
  }

  _prepareSnapshot () {
    const { item } = this._getStoredItem()
    const snapshot = {}
    for (const [key, value] of Object.entries(this._attributes)) {
      snapshot[key] = item[key] || this._getFallback(typeof value)
    }
    return snapshot
  }

  _getFallback (propertyType) {
    let fallback
    switch (propertyType) {
      case 'number': fallback = 0; break
      case 'boolean': fallback = false; break
      default: fallback = ''; break
    }
    return fallback
  }
}
