<template>
  <list-params
    class="users-list-params"
    v-model="form"
    :initial-value="value"
    @form-apply="$emit('input', form)"
  >
    <template slot="form-rows">
      <list-params-sort
        class="users-list-params__row"
        v-model="form.sort"
        :options="sortOptions"
        :default-value="defaultSort"
      />

      <list-params-location
        class="users-list-params__row"
        v-model="form.location"
      />
    </template>
  </list-params>
</template>

<script>
import {
  USERS_QUERY_SORTS,
  USERS_TAB_DEFAULT_SORT,
} from 'Constants/usersUrlParams'
import {
  ListParams,
  ListParamsSort,
  ListParamsLocation,
} from 'Common/ListParams'
import { can } from 'Utils/userHelpers'
import { USER_CLAIMS } from 'Constants/userClaims'

export default {
  name: 'users-list-params',
  components: {
    ListParams,
    ListParamsSort,
    ListParamsLocation,
  },

  props: {
    value: {
      type: Object,
      required: true,
      validator (value) {
        return typeof value.sort === 'string' &&
          typeof value.location === 'string'
      },
    },
  },

  data () {
    return {
      form: {
        sort: '',
        location: '',
      },
    }
  },

  computed: {
    sortOptions () {
      return [
        {
          value: USERS_QUERY_SORTS.createdAtDesc,
          label: this.$t('SORT_NEW_FIRST_OPT'),
        },
        {
          value: USERS_QUERY_SORTS.createdAt,
          label: this.$t('SORT_NEW_LAST_OPT'),
        },
        {
          value: USERS_QUERY_SORTS.firstName,
          label: this.$t('SORT_FIRST_NAME_OPT'),
        },
        {
          value: USERS_QUERY_SORTS.lastName,
          label: this.$t('SORT_LAST_NAME_OPT'),
        },
        {
          value: USERS_QUERY_SORTS.city,
          label: this.$t('SORT_CITY_OPT'),
        },
        ...(can(USER_CLAIMS.SHELF_AP_FEATURES)
          ? [
            {
              value: USERS_QUERY_SORTS.company,
              label: this.$t('SORT_COMPANY_OPT'),
            }
          ] : []
        )
      ]
    },

    defaultSort () {
      return USERS_TAB_DEFAULT_SORT[this.$route.params.tab]
    },
  },
}
</script>

<i18n>
{
  "en": {
    "SORT_NEW_FIRST_OPT": "New first",
    "SORT_NEW_LAST_OPT": "New last",
    "SORT_FIRST_NAME_OPT": "First name",
    "SORT_FIRST_NAME_DESC_OPT": "First name, descending",
    "SORT_LAST_NAME_OPT": "Last name",
    "SORT_LAST_NAME_DESC_OPT": "Last name, descending",
    "SORT_CITY_OPT": "City",
    "SORT_CITY_DESC_OPT": "City, descending",
    "SORT_COMPANY_OPT": "Company",
    "SORT_COMPANY_DESC_OPT": "Company, descending"
  },
  "ka": {
    "SORT_NEW_FIRST_OPT": "ბოლო მომართვები",
    "SORT_NEW_LAST_OPT": "ადრინდელი მომართვები",
    "SORT_FIRST_NAME_OPT": "სახელი",
    "SORT_FIRST_NAME_DESC_OPT": "სახელი, ანბანი",
    "SORT_LAST_NAME_OPT": "გგვარი",
    "SORT_LAST_NAME_DESC_OPT": "გგვარი, ანბანი",
    "SORT_CITY_OPT": "ქალაქი",
    "SORT_CITY_DESC_OPT": "ქალაქი, ანბანი",
    "SORT_COMPANY_OPT": "კომპანია",
    "SORT_COMPANY_DESC_OPT": "კომპანია, ანბანი"
  },
  "ru": {
    "SORT_NEW_FIRST_OPT": "Сначала новые",
    "SORT_NEW_LAST_OPT": "Сначала старые",
    "SORT_FIRST_NAME_OPT": "Имя",
    "SORT_FIRST_NAME_DESC_OPT": "Имя по убыванию",
    "SORT_LAST_NAME_OPT": "Фамилия",
    "SORT_LAST_NAME_DESC_OPT": "Фамилия, по убыванию",
    "SORT_CITY_OPT": "Город",
    "SORT_CITY_DESC_OPT": "Город, по убыванию",
    "SORT_COMPANY_OPT": "Компания",
    "SORT_COMPANY_DESC_OPT": "Компания, по убыванию"
  },
  "uk": {
    "SORT_NEW_FIRST_OPT": "Спочатку нові",
    "SORT_NEW_LAST_OPT": "Спочатку старі",
    "SORT_FIRST_NAME_OPT": "Ім’я",
    "SORT_FIRST_NAME_DESC_OPT": "Ім’я, по спаданню",
    "SORT_LAST_NAME_OPT": "Прізвище",
    "SORT_LAST_NAME_DESC_OPT": "Прізвище, по спаданню",
    "SORT_CITY_OPT": "Місто",
    "SORT_CITY_DESC_OPT": "Місто, по спаданню",
    "SORT_COMPANY_OPT": "Компанія",
    "SORT_COMPANY_DESC_OPT": "Компанія, по спаданню"
  }
}
</i18n>
