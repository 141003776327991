<template>
  <div class="user-cell-name-ava cells-cmn__cell">
    <lazy-ava-img
      class="cells-cmn__ava"
      :alt="fullName"
      :title="fullName"
      :src="userEntry.avatarLink"
    />

    <router-link
      :to="{ params: { userId: userEntry.id }, query: $route.query }"
      class="cells-cmn__btn user-cell-name-ava__link"
      @click="isModalShown = true"
    >
      <span
        class="cells-cmn__str cells-cmn__str_nb"
        :title="fullName"
      >
        {{ fullName }}
      </span>

      <template v-if="isPro">
        <span class="cells-cmn__str user-cell-name-ava__pro-mark">
          {{ $t('PRO_TXT') }}
        </span>
      </template>
    </router-link>
  </div>
</template>

<script>
import LazyAvaImg from 'Common/LazyAvaImg'
import formatFullName from 'Utils/formatFullName'
import { safeUserName } from 'Utils/safeUserName'
import { UserEntry } from 'Models/UserEntry'

export default {
  name: 'user-cell-name-ava',

  components: {
    LazyAvaImg,
  },

  props: {
    userEntry: {
      type: UserEntry,
      required: true,
    },
  },

  data () {
    return {
      isModalShown: false,
    }
  },

  computed: {
    fullName () {
      return safeUserName(formatFullName(this.userEntry))
    },

    isPro () {
      return this.userEntry.accountType === UserEntry.accountTypesEnum.business
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/common/cells/cells.scss";

.user-cell-name-ava {
  &__link {
    text-decoration: none;
  }

  &__pro-mark {
    color: $color-user-pro;
  }
}
</style>

<i18n>
{
  "en": {
    "PRO_TXT": "PRO"
  },
  "ka": {
    "PRO_TXT": "PRO"
  },
  "ru": {
    "PRO_TXT": "PRO"
  },
  "uk": {
    "PRO_TXT": "PRO"
  }
}
</i18n>
