<template>
  <button
    class="op-verify-email user-op user-op__btn"
    @click="onClick"
  >
    <ui-icon
      class="user-op__btn-ico"
      icon="message-alt"
    />

    <span class="user-op__btn-txt">
      {{ $t('VERIFY_EMAIL_BTN') }}
    </span>
  </button>
</template>

<script>
import { UiIcon } from '@shelf.network/ui-kit'
import { UserEntry } from 'Models/UserEntry'
import { VerifyUserEmail } from '../../commands/VerifyUserEmail'

export default {
  name: 'op-verify-email',

  components: {
    UiIcon,
  },

  props: {
    userEntry: {
      type: UserEntry,
      required: true,
    },
  },

  methods: {
    async onClick () {
      const cmd = new VerifyUserEmail({ userEntry: this.userEntry })
      await cmd.execute()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../styles/users-operations.scss";
</style>

<i18n>
{
  "en": {
    "VERIFY_EMAIL_BTN": "Verify email"
  },
  "ka": {
    "VERIFY_EMAIL_BTN": "ელ.ფოსტის ვერიფიკაცია"
  },
  "ru": {
    "VERIFY_EMAIL_BTN": "Подтвердить эл. почту"
  },
  "uk": {
    "VERIFY_EMAIL_BTN": "Підтвердити ел. пошту"
  }
}
</i18n>
