<template>
  <div class="op-edit-credentials">
    <button
      class="op-edit-credentials user-op user-op__btn"
      @click="showEditCredentialsModal()"
    >
      <ui-icon
        class="user-op__btn-ico"
        icon="pen"
      />

      <span class="user-op__btn-txt">
        {{ $t('EDIT_CREDENTIALS_BTN') }}
      </span>
    </button>

    <op-edit-credentials-modal
      class="op-edit-credentials__modal"
      v-if="isEditCredentialsModalShown"
      :user-entry="userEntry"
      @close="hideEditCredentialsModal()"
    />
  </div>
</template>

<script>
import { UiIcon } from '@shelf.network/ui-kit'
import { UserEntry } from 'Models/UserEntry'
import OpEditCredentialsModal from './OpEditCredentialsModal'

export default {
  name: 'op-edit-credentials',

  components: {
    UiIcon,
    OpEditCredentialsModal,
  },

  props: {
    userEntry: {
      type: UserEntry,
      required: true,
    },
  },

  data () {
    return {
      isEditCredentialsModalShown: false,
    }
  },

  methods: {
    onClick () {
      this.isEditCredentialsModalShown = true
    },

    showEditCredentialsModal () {
      this.isEditCredentialsModalShown = true
      this.$emit('op-started')
    },

    hideEditCredentialsModal () {
      this.isEditCredentialsModalShown = false
      this.$emit('op-finished')
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../styles/users-operations.scss";
</style>

<i18n>
{
  "en": {
    "EDIT_CREDENTIALS_BTN": "Edit user credentials"
  },
  "ka": {
    "EDIT_CREDENTIALS_BTN": "მონაცემიბის შეცვლა"
  },
  "ru": {
    "EDIT_CREDENTIALS_BTN": "Сменить пароль"
  },
  "uk": {
    "EDIT_CREDENTIALS_BTN": "Змінити пароль"
  }
}
</i18n>
