import { Command } from '@/commands/Command'
import { sdk } from 'Services/shelfNetworkSdk'
import { rootGet, rootDispatch } from 'Store/helpers/rootHelpers'
import { userGetters } from 'Store/entities/User/types'
import { uiUsersActions } from '../store/types'

/**
 * @typedef {object} CreateUserArgsDef
 * @property {object} attributes Attributes of the user to create
 *
 * @typedef {import('@/commands/Command').CommandArgs} CommandArgs
 * @typedef {CommandArgs & CreateUserArgsDef} CreateUserArgs
 */

export class CreateUser extends Command {
  /**
   * Create a configured CreateUser command
   * @param {CreateUserArgs} opts
   */
  constructor (opts) {
    super(opts)

    this._attrs = opts.attributes
  }

  async execute () {
    try {
      const { password, ...attrs } = this._attrs
      const platformId = rootGet(`entities/user/${userGetters.PLATFORM_ID}`)
      attrs.platformId = attrs.platformId || platformId
      const { data } = await sdk.horizon.account.create(attrs, null, password)

      rootDispatch(`ui/users/${uiUsersActions.PUSH_ITEM}`, data)

      const msg = this.$t('CMD.USERS.CREATE.SUCCESS_MSG', { name: this._attrs.firstName })
      this._reportSuccess(msg)
    } catch (error) {
      const msg = error.httpStatus === 409
        ? this.$t('CMD.USERS.CREATE.FAILURE_EXISTS_MSG')
        : this.$t('CMD.USERS.CREATE.FAILURE_MSG', { name: this._attrs.firstName })
      this._reportError(error, msg)
    }
  }
}
