<template>
  <button
    class="op-block user-op user-op__btn"
    :disabled="isCheckingBlocked || isExecuting"
    @click="onClick"
  >
    <template v-if="isBlocked">
      <icon-combo
        class="op-block__icon-combo user-op__btn-ico"
        :class="'op-block__icon-combo_unblock'"
        icon="user"
        icon-addition="plus-bold"
      />

      <span class="user-op__btn-txt">
        {{ $t('UNBLOCK_BTN') }}
      </span>
    </template>

    <template v-else>
      <icon-combo
        class="op-block__icon-combo user-op__btn-ico"
        icon="user"
        icon-addition="cross-bold"
      />

      <span class="user-op__btn-txt">
        {{ $t('BLOCK_BTN') }}
      </span>
    </template>

    <ui-spinner
      class="op-block__spinner user-op__overlay-spinner"
      v-if="isCheckingBlocked || isExecuting"
      type="pills"
      :overlay="true"
    />
  </button>
</template>

<script>
import IconCombo from 'Common/IconCombo'
import { UiSpinner } from '@shelf.network/ui-kit'
import { sdk } from 'Services/shelfNetworkSdk'
import { UserEntry } from 'Models/UserEntry'
import { BlockUser } from '../../commands/BlockUser'
import { UnblockUser } from '../../commands/UnblockUser'

export default {
  name: 'op-block',

  components: {
    IconCombo,
    UiSpinner,
  },

  props: {
    userEntry: {
      type: UserEntry,
      required: true,
    },
  },

  data () {
    return {
      isBlocked: false,
      isExecuting: false,
      isCheckingBlocked: false,
    }
  },

  created () {
    this.checkIsBlocked()
  },

  methods: {
    onClick () {
      if (this.isCheckingBlocked || this.isExecuting) return

      if (this.isBlocked) {
        this.unblock()
      } else {
        this.block()
      }
    },

    async block () {
      const cmd = new BlockUser({
        userEntry: this.userEntry,
        isForcedErrorThrow: true,
      })

      this.isExecuting = true
      try {
        await cmd.execute()
        this.isBlocked = true
      } catch (error) {
        // noop
      }
      this.isExecuting = false
    },

    async unblock () {
      const cmd = new UnblockUser({
        userEntry: this.userEntry,
        isForcedErrorThrow: true,
      })

      this.isExecuting = true
      try {
        await cmd.execute()
        this.isBlocked = false
      } catch (error) {
        // noop
      }
      this.isExecuting = false
    },

    async checkIsBlocked () {
      this.isCheckingBlocked = true
      try {
        const { data } = await sdk.gateway.getAccountsBlacklist()
        this.isBlocked = Boolean(data.find(el => el.id === this.userEntry.id))
      } catch (error) {
        console.error(error)
      }
      this.isCheckingBlocked = false
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../styles/users-operations.scss";

.op-block {
  position: relative;

  &__icon-combo {
    --addition-color: #{$color-flag-is-error};

    &_unblock {
      --addition-color: #{$color-flag-is-success};
    }
  }
}
</style>

<i18n>
{
  "en": {
    "BLOCK_BTN": "Block the user",
    "UNBLOCK_BTN": "Unblock the user"
  },
  "ka": {
    "BLOCK_BTN": "დაბლოკვა",
    "UNBLOCK_BTN": "განბლოკვა"
  },
  "ru": {
    "BLOCK_BTN": "Блокировать",
    "UNBLOCK_BTN": "Разблокировать"
  },
  "uk": {
    "BLOCK_BTN": "Блокувати",
    "UNBLOCK_BTN": "Розблокувати"
  }
}
</i18n>
