<template>
  <div class="user-card-phone-edit">
    <template v-if="!isEditing">
      <ui-icon
        class="user-card-phone-edit__phone-ico"
        icon="call"
      />

      <button
        class="user-card-phone-edit__phone-str-btn"
        @click="startEdit()"
      >
        {{ formattedUserPhone }}
      </button>

      <template v-if="userEntry.isPhoneVerified">
        <ui-icon
          class="user-card-phone-edit__verified-ico"
          icon="success"
          :title="$t('PHONE_VERIFIED_HINT')"
        />
      </template>

      <template v-else>
        <button
          class="user-card-phone-edit__verify-btn"
          frame="none"
          fill="none"
          @click="verify()"
        >
          {{ $t('VERIFY_BTN') }}

          <ui-spinner
            v-if="isProcessingVerifyPhone"
            class="cell-participant-reject__spinner"
            type="pills"
            :overlay="true"
          />
        </button>
      </template>
    </template>

    <template v-else>
      <form
        class="user-card-phone-edit__form"
        @submit.prevent="submit()"
        @reset.prevent="stopEdit()"
      >
        <user-card-field
          class="user-card-phone-edit__field"
          field="UiPhone"
          v-model="form.phone"
          :errors="formErrors.phone.map(jsvLocalizeError)"
          :pinned-countries="phoneCountries"
          filter-countries
          @input="formErrors.phone = []"
        />

        <div class="user-card-phone-edit__form-actions">
          <ui-button
            class="user-card-phone-edit__inline-btn"
            frame="none"
            fill="none"
            type="submit"
          >
            <ui-icon
              class="user-card-phone-edit__inline-btn-ico"
              icon="success"
            />
          </ui-button>

          <ui-button
            class="user-card-phone-edit__inline-btn"
            frame="none"
            fill="none"
            type="reset"
          >
            <ui-icon
              class="user-card-phone-edit__inline-btn-ico"
              icon="close"
            />
          </ui-button>
        </div>
      </form>
    </template>
  </div>
</template>

<script>
import {
  UiIcon,
  UiButton,
  UiSpinner,
  formatPhone
} from '@shelf.network/ui-kit'
import UserCardField from './UserCardField'
import { UserEntry } from 'Models/UserEntry'
import { UpdateUser } from '../../commands/UpdateUser'
import validationSchema from '../../json-schemas/update-phone-validation.json'
import { jsv } from 'Utils/jsv'
import { jsvLocalizeError } from 'Utils/jsvLocalizeError'
import { jsvMimicryError } from 'Utils/jsvMimicryError'
import { VerifyUserPhone } from '../../commands/VerifyUserPhone'
import { PHONE_COUNTRIES } from 'Constants/phoneCountries'

export default {
  name: 'user-card-phone-edit',

  components: {
    UiIcon,
    UiButton,
    UiSpinner,
    UserCardField,
  },

  props: {
    userEntry: {
      type: UserEntry,
      required: true,
    }
  },

  data () {
    return {
      isEditing: false,
      form: this.entryToForm(),
      formErrors: this.entryToFormErrorsDefault(),
      isProcessingVerifyPhone: false,
    }
  },

  computed: {
    formattedUserPhone () {
      return formatPhone(this.userEntry.phone)
    },

    isPro () {
      return this.userEntry.accountType === UserEntry.accountTypesEnum.business
    },

    phoneCountries: () => PHONE_COUNTRIES,
  },

  created () {
    this.$watch(
      () => [this.userEntry.phone],
      () => { this.form = this.entryToForm() },
    )
  },

  methods: {
    entryToForm () {
      return {
        phone: this.userEntry.phone,
      }
    },

    entryToFormErrorsDefault () {
      const newObjEntries = Object.entries(this.entryToForm())
        .map(([key]) => [key, []])
      return Object.fromEntries(newObjEntries)
    },

    checkFormValid () {
      if (Object.values(this.formErrors).some(el => el.length > 0)) return false

      const result = jsv.validate(validationSchema, this.form)
      if (result.isValid) return true

      Object.assign(this.formErrors, result.byField())
      return false
    },

    async verify () {
      this.isProcessingVerifyPhone = true
      const cmd = new VerifyUserPhone({
        userEntry: this.userEntry,
      })
      await cmd.execute()
      this.isProcessingVerifyPhone = false
    },

    async submit () {
      if (!this.checkFormValid()) return

      const cmd = new UpdateUser({
        isForcedErrorThrow: true,
        userEntry: this.userEntry,
        attributes: {
          phoneNumber: this.form.phone,
          phoneVerified: false,
        },
      })

      try {
        await cmd.execute()
        this.stopEdit()
      } catch (error) {
        if (error.httpStatus === 409) {
          this.formErrors.phone.push(jsvMimicryError(this.$t('PHONE_CONFLICT_ERR')))
        }
      }
    },

    startEdit () {
      this.form = this.entryToForm()
      this.isEditing = true
    },

    stopEdit () {
      this.isEditing = false
    },

    jsvLocalizeError,
  }
}
</script>

<style lang="scss" scoped>
.user-card-phone-edit {
  @include respond(--font-size, 1.2em, 1.2em, 1.1em, 1em);

  display: flex;
  align-items: center;
  line-height: 2em;

  &__phone-str-btn {
    font-size: var(--font-size);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: none;
    border: none;
  }

  &__phone-ico {
    font-size: var(--font-size);
    margin-right: 0.25ch;
  }

  &__verified-ico {
    font-size: var(--font-size);
    color: $color-flag-is-success;
    margin-left: 0.5em;
  }

  &__verify-btn {
    color: $color-sys-info;
    background: none;
    border: none;
    position: relative;
    padding: 0 0.5em;

    &:hover {
      color: mix($color-sys-info, $color-ui-default, 80);
    }
  }

  &__inline-btn {
    line-height: 1;
    margin-left: 0.5em;
    justify-self: start;

    /deep/ .ui-button__button {
      padding: 0.25em;
    }

    &-ico {
      display: block;
      font-size: 1.5em;
    }
  }

  &__field {
    /deep/ .user-card-field__field-component {
      font-size: 0.9em;
    }

    /deep/ .ui-phone__input-wrp,
    /deep/ .ui-button,
    /deep/ .ui-phone__input,
    /deep/ .ui-button__button {
      font-size: inherit;
    }
  }

  &__form {
    display: grid;
    width: 100%;
    margin-bottom: 1em;
    gap: 0.5em;
    grid: auto / repeat(auto-fit, minmax(auto, 14em));

    &-actions {
      display: grid;
      justify-content: start;
      align-items: center;
      grid: auto / auto-flow auto;
      height: 2.8em;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "VERIFY_BTN": "Verify",
    "PHONE_VERIFIED_HINT": "Phone verified",
    "PHONE_CONFLICT_ERR": "Phone is already in use"
  },
  "ka": {
    "VERIFY_BTN": "ვერიფიცირება",
    "PHONE_VERIFIED_HINT": "ტელეფონი ვერიფიცირებულია",
    "PHONE_CONFLICT_ERR": "ტელეფონი უკვე რეგისტრირებულია"
  },
  "ru": {
    "VERIFY_BTN": "Подтвердить",
    "PHONE_VERIFIED_HINT": "Телефон подтвержден",
    "PHONE_CONFLICT_ERR": "Телефон уже используется"
  },
  "uk": {
    "VERIFY_BTN": "Підтвердити",
    "PHONE_VERIFIED_HINT": "Телефон підтверджено",
    "PHONE_CONFLICT_ERR": "Телефон вже використовується"
  }
}
</i18n>
