<template>
  <div class="users">
    <page-subnav class="users__subnav">
      <ui-button
        class="users__create-btn"
        @click="isUserCreateModalShown = true"
      >
        {{ $t('CREATE_USER_BTN') }}
        <ui-icon icon="plus" />
      </ui-button>

      <users-list-params
        class="users__subnav-action"
        :value="{
          sort: $route.query.sort || '',
          location: $route.query.location || '',
        }"
        @input="onListParamsChange"
      />

      <subnav-search
        class="users__subnav-action users__subnav-action_ml"
        :value="$route.query.search"
        @input="onSearch"
      />

      <template slot="right">
        <router-link
          class="users__tab"
          active-class="users__tab_active"
          :to="{
            params: { tab: USERS_URL_PARAM_TABS.regular },
          }"
        >
          {{ $t('REGULAR_TAB') }}
        </router-link>

        <router-link
          class="users__tab"
          active-class="users__tab_active"
          :to="{
            params: { tab: USERS_URL_PARAM_TABS.pro },
          }"
        >
          {{ $t('PRO_TAB') }}
        </router-link>

        <router-link
          class="users__tab"
          active-class="users__tab_active"
          :to="{
            params: { tab: USERS_URL_PARAM_TABS.brokers },
          }"
        >
          {{ $t('BROKERS_TAB') }}
        </router-link>

        <router-link
          class="users__tab"
          active-class="users__tab_active"
          :to="{
            params: { tab: USERS_URL_PARAM_TABS.dealers },
          }"
        >
          {{ $t('DEALERS_TAB') }}
        </router-link>
      </template>
    </page-subnav>

    <users-list
      class="users__list"
      :list="listProcessed"
      :template="template"
      @update-list-ask="loadList"
      @more-ask="loadMore"
    />

    <user-card-modal
      class="users__item-card-modal"
      :users-list="listProcessed"
      :is-users-loading="isLoading"
      :user-id="$route.params.userId"
      :user-tab="$route.params.userTab"
      @update:userId="$router.push({
        params: { userId: $event },
        query: $route.query,
      })"
      @update:userTab="$router.push({
        params: { userTab: $event },
        query: $route.query,
      })"
      @close="$router.push({
        params: { userId: null, userTab: null },
        query: $route.query,
      })"
    />

    <user-create-modal
      class="users__create-modal"
      v-if="isUserCreateModalShown"
      @close="isUserCreateModalShown = false"
    />
  </div>
</template>

<script>
import PageSubnav from 'Common/PageSubnav'
import SubnavSearch from 'Common/SubnavSearch'
import { UiButton, UiIcon } from '@shelf.network/ui-kit'
import UsersList from './components/UsersList'
import UsersListParams from './components/UsersListParams'
import UserCreateModal from './components/UserCreateModal'
import UserCardModal from './components/UserCardModal'
import { uiUsersActions, uiUsersMutations, uiUsersGetters } from './store/types'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { showError } from 'Utils/notifications'
import {
  USERS_URL_PARAM_TABS,
  USERS_TAB_DEFAULT_SORT,
  USERS_QUERY_SORTS,
} from 'Constants/usersUrlParams'
import { USERS_LIST_TEMPLATES } from './constants'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import debounce from 'lodash/debounce'
import { UserEntry } from 'Models/UserEntry'
import { Location } from 'Models/Location'
import { fieldComparator, compareDate, compareAlphabetically } from 'Utils/arrayHelpers'

const SORT_TO_COMPARATOR_MAP = {
  [USERS_QUERY_SORTS.createdAt]: fieldComparator('createdAt', compareDate),
  [USERS_QUERY_SORTS.createdAtDesc]: fieldComparator('createdAt', compareDate, '-'),
  [USERS_QUERY_SORTS.firstName]: fieldComparator('firstName', compareAlphabetically),
  [USERS_QUERY_SORTS.firstNameDesc]: fieldComparator('firstName', compareAlphabetically, '-'),
  [USERS_QUERY_SORTS.lastName]: fieldComparator('lastName', compareAlphabetically),
  [USERS_QUERY_SORTS.lastNameDesc]: fieldComparator('lastName', compareAlphabetically, '-'),
  [USERS_QUERY_SORTS.city]: fieldComparator('city', compareAlphabetically),
  [USERS_QUERY_SORTS.cityDesc]: fieldComparator('city', compareAlphabetically, '-'),
  [USERS_QUERY_SORTS.company]: fieldComparator('company', compareAlphabetically),
  [USERS_QUERY_SORTS.companyDesc]: fieldComparator('company', compareAlphabetically, '-'),
}

const TAB_TO_PREDICATE_MAP = {
  [USERS_URL_PARAM_TABS.regular]: ue =>
    ue.accountType === UserEntry.accountTypesEnum.user,
  [USERS_URL_PARAM_TABS.pro]: ue =>
    ue.accountType === UserEntry.accountTypesEnum.business,
  [USERS_URL_PARAM_TABS.brokers]: ue =>
    ue.accountType === UserEntry.accountTypesEnum.broker,
  [USERS_URL_PARAM_TABS.dealers]: ue =>
    ue.accountType === UserEntry.accountTypesEnum.dealer,
}

const TAB_TO_TEMPLATE_MAP = Object.freeze({
  [USERS_URL_PARAM_TABS.regular]: USERS_LIST_TEMPLATES.regular,
  [USERS_URL_PARAM_TABS.pro]: USERS_LIST_TEMPLATES.pro,
  [USERS_URL_PARAM_TABS.brokers]: USERS_LIST_TEMPLATES.brokers,
  [USERS_URL_PARAM_TABS.dealers]: USERS_LIST_TEMPLATES.dealers,
})

export default {
  name: 'users',

  components: {
    PageSubnav,
    SubnavSearch,
    UiButton,
    UiIcon,
    UsersList,
    UsersListParams,
    UserCreateModal,
    UserCardModal,
  },

  data () {
    return {
      USERS_URL_PARAM_TABS,
      isUserCreateModalShown: false,
      template: TAB_TO_TEMPLATE_MAP[USERS_URL_PARAM_TABS.regular],
      filteringPredicate: () => true,
      sortingComparator: () => { },
    }
  },

  computed: {
    ...mapGetters('ui/users', {
      list: uiUsersGetters.LIST,
      isLoading: uiUsersGetters.IS_LOADING,
    }),

    listProcessed () {
      /**
       * Simulate back-end’s filter and sort so user changes are applied
       * immediately to the list
       */
      return this.list
        .filter(this.filteringPredicate)
        .sort(this.sortingComparator)
    },

    tabDefaultSort () {
      return USERS_TAB_DEFAULT_SORT[this.$route.params.tab]
    },

    metaTitle () {
      const tabTitles = {
        [USERS_URL_PARAM_TABS.regular]: this.$t('META_TITLE_REGULAR'),
        [USERS_URL_PARAM_TABS.pro]: this.$t('META_TITLE_PRO'),
        [USERS_URL_PARAM_TABS.brokers]: this.$t('META_TITLE_BROKERS'),
        [USERS_URL_PARAM_TABS.dealers]: this.$t('META_TITLE_DEALERS'),
      }
      return tabTitles[this.$route.params.tab]
    },
  },

  watch: {
    '$route.params.tab': {
      immediate: true,
      handler (tab, oldTab) {
        if (isEqual(tab, oldTab)) return
        this.onListParamsChange(this.$route.query)
      }
    },
  },

  methods: {
    ...mapActions('ui/users', {
      loadListAction: uiUsersActions.LOAD_LIST,
      loadBrokersAction: uiUsersActions.LOAD_BROKERS,
      loadMore: uiUsersActions.LOAD_MORE,
    }),
    ...mapMutations('ui/users', {
      setIsLoading: uiUsersMutations.SET_IS_LOADING,
    }),
    omit,

    onListParamsChange (input = {}) {
      const query = { ...this.$route.query }

      query.sort = input.sort === this.tabDefaultSort
        ? undefined
        : input.sort || undefined
      query.location = input.location || undefined

      if (!isEqual(query, this.$route.query)) {
        this.$router.push({ query })
      }
      this.loadList()
    },

    onSearch (str) {
      str = str.trim()
      if ((this.$route.query.search || '') === str) return
      this.setIsLoading(true)
      this.applySearchDebounced(str)
    },

    applySearchDebounced: debounce(async function (search) {
      await this.applySearch(search)
    }, 1000),

    applySearch (str) {
      const query = { ...this.$route.query }
      if ((query.search || '') === str) return
      query.search = str || undefined

      this.$router.push({ query })
      this.loadList()
    },

    async loadList () {
      try {
        const payload = this.buildLoadListPayload()
        await this.loadListAction(payload)
        this.postLoad()
      } catch (error) {
        if (!error.isCanceled) {
          showError(this.$t('LIST_FETCH_FAILED_NOTIFY'))
          console.error(error)
        }
      }
    },

    buildLoadListPayload () {
      const result = { filter: {} }
      const query = this.$route.query || {}

      result.sort = query.sort || this.tabDefaultSort
      result.type = {
        [USERS_URL_PARAM_TABS.regular]: UserEntry.accountTypesEnum.user,
        [USERS_URL_PARAM_TABS.pro]: UserEntry.accountTypesEnum.business,
        [USERS_URL_PARAM_TABS.brokers]: UserEntry.accountTypesEnum.broker,
        [USERS_URL_PARAM_TABS.dealers]: UserEntry.accountTypesEnum.dealer,
      }[this.$route.params.tab]

      if (query.location) {
        const location = Location.fromString(query.location)
        if (location.countryId) result.filter.countryId = location.countryId
        if (location.regionId) result.filter.region = location.regionId
        if (location.cityId) result.filter.cityId = location.cityId
      }

      if (query.search) {
        result.filter.search = query.search || ''
      }

      return result
    },

    postLoad () {
      const sort = this.$route.query.sort || this.tabDefaultSort
      const tab = this.$route.params.tab

      this.template = TAB_TO_TEMPLATE_MAP[tab]
      this.sortingComparator = SORT_TO_COMPARATOR_MAP[sort]
      this.filteringPredicate = TAB_TO_PREDICATE_MAP[tab]
    },
  },

  metaInfo () {
    return {
      title: this.metaTitle,
      titleTemplate: `%s | ${this.$t('COMMON.APP_TITLE')}`,
    }
  }
}
</script>

<style lang="scss" scoped>
.users {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__tab {
    position: relative;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    text-decoration: none;
    margin: 0 1.4em;
    color: $color-ui-secondary;
    font-weight: 500;
    letter-spacing: 0.03em;

    &_active {
      color: $color-dark;
      box-shadow: 0 -1px 0 $color-dark inset !important;
    }

    &:hover {
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1) inset;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "CREATE_USER_BTN": "New user",
    "REGULAR_TAB": "USERS",
    "PRO_TAB": "PRO-USERS",
    "BROKERS_TAB": "BROKERS",
    "DEALERS_TAB": "DEALERS",
    "META_TITLE_REGULAR": "Users",
    "META_TITLE_PRO": "PRO users",
    "META_TITLE_BROKERS": "Brokers",
    "META_TITLE_DEALERS": "Dealers",
    "LIST_FETCH_FAILED_NOTIFY": "Cannot load the users list. Please try again later or contact the system owner."
  },
  "ka": {
    "CREATE_USER_BTN": "ახალი მომხმარებელი",
    "REGULAR_TAB": "ᲛᲝᲛᲮᲛᲐᲠᲔᲑᲚᲔᲑᲘ",
    "PRO_TAB": "PRO-ᲛᲝᲛᲮᲛᲐᲠᲔᲑᲚᲔᲑᲘ",
    "BROKERS_TAB": "ᲑᲠᲝᲙᲔᲠᲔᲑᲘ",
    "DEALERS_TAB": "ᲓᲘᲚᲔᲠᲘ",
    "META_TITLE_REGULAR": "მომხმარებლები",
    "META_TITLE_PRO": "PRO მომხმარებლები",
    "META_TITLE_BROKERS": "ბროკერები",
    "META_TITLE_DEALERS": "დილერი",
    "LIST_FETCH_FAILED_NOTIFY": "მომხმარებლების სია ვერ ჩაიტვირთა. მიმართეთ ადმინისტრატორს."
  },
  "ru": {
    "CREATE_USER_BTN": "Новый",
    "REGULAR_TAB": "ПОЛЬЗОВАТЕЛИ",
    "PRO_TAB": "PRO ПОЛЬЗОВАТЕЛИ",
    "BROKERS_TAB": "БРОКЕРЫ",
    "DEALERS_TAB": "ДИЛЕРЫ",
    "META_TITLE_REGULAR": "Пользователи",
    "META_TITLE_PRO": "PRO пользователи",
    "META_TITLE_BROKERS": "Брокеры",
    "META_TITLE_DEALERS": "Дилеры",
    "LIST_FETCH_FAILED_NOTIFY": "Не удалось загрузить список пользователей. Повторите попытку позже или свяжитесь с владельцем системы."
  },
  "uk": {
    "CREATE_USER_BTN": "Новий",
    "REGULAR_TAB": "КОРИСТУВАЧІ",
    "PRO_TAB": "PRO КОРИСТУВАЧІ",
    "BROKERS_TAB": "БРОКЕРИ",
    "DEALERS_TAB": "ДИЛЕРИ",
    "META_TITLE_REGULAR": "Користувачі",
    "META_TITLE_PRO": "PRO користувачі",
    "META_TITLE_BROKERS": "Брокери",
    "META_TITLE_DEALERS": "Дилери",
    "LIST_FETCH_FAILED_NOTIFY": "Не вдалось завантажити список користувачів. Повторіть спробу пізніше або зв’яжіться з власником системи."
  }
}
</i18n>
