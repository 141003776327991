<template>
  <div class="user-card-tab-attrs">
    <form class="user-card-tab-attrs__form">
      <ui-row
        v-if="isAdmin"
        class="user-card-tab-attrs__row"
      >
        <ui-col
          class="user-card-tab-attrs__col"
          lg="6"
          xs="12"
        >
          <user-card-field
            class="user-card-tab-attrs__field"
            field="SelectAccountType"
            :label="$t('ACCOUNT_TYPE_LBL')"
            v-model="form.accountType"
            :is-disabled="isAccountTypeSelectDisabled"
            :errors="formErrors.accountType.map(jsvLocalizeError)"
            @input="formErrors.accountType = []; submitAccountType()"
          />
        </ui-col>
      </ui-row>

      <ui-row class="user-card-tab-attrs__row">
        <ui-col
          class="user-card-tab-attrs__col"
          lg="6"
          xs="12"
        >
          <user-card-field
            class="user-card-tab-attrs__field"
            field="SelectLocation"
            :label="$t('LOCATION_LBL')"
            v-model="form.location"
            :errors="formErrors.location.map(jsvLocalizeError)"
            @input="formErrors.location = []; submitLocation()"
          />
        </ui-col>

        <ui-col
          class="user-card-tab-attrs__col"
          lg="6"
          xs="12"
        >
          <user-card-field
            class="user-card-tab-attrs__field user-card-tab-attrs__field_email"
            field="UiText"
            :label="$t('EMAIL_LBL')"
            :placeholder="$t('EMAIL_PH')"
            v-model="form.email"
            :errors="formErrors.email.map(jsvLocalizeError)"
            @input="formErrors.email = []"
            @blur="submit({ email: form.email })"
          >
            <template v-if="userEntry.isEmailVerified">
              <ui-icon
                slot="dock-right"
                class="user-card-tab-attrs__check-ico-docked"
                icon="success"
                :title="$t('VERIFIED_EMAIL_HINT')"
              />
            </template>
            <template v-else>
              <button
                class="user-card-tab-attrs__verify-email-btn"
                type="button"
                @click="verifyEmail"
                :disabled="isProcessingVerifyEmail"
              >
                {{ $t('VERIFY_EMAIL_BTN') }}

                <ui-spinner
                  v-if="isProcessingVerifyEmail"
                  class="cell-participant-reject__spinner"
                  type="pills"
                  :overlay="true"
                />
              </button>
            </template>
          </user-card-field>
        </ui-col>
      </ui-row>

      <template v-if="$can($USER_CLAIMS.SHELF_AP_FEATURES)">
        <ui-row class="user-card-tab-attrs__row">
          <ui-col
            class="user-card-tab-attrs__col"
            lg="6"
            xs="12"
          >
            <user-card-field
              class="user-card-tab-attrs__field"
              field="UiText"
              :label="$t('COMPANY_LBL')"
              :placeholder="$t('COMPANY_PH')"
              v-model="form.company"
              :errors="formErrors.company.map(jsvLocalizeError)"
              @input="formErrors.company = []"
              @blur="submit({ company: form.company })"
              maxlength="30"
            />
          </ui-col>

          <ui-col
            class="user-card-tab-attrs__col"
            lg="6"
            xs="12"
          >
            <user-card-field
              class="user-card-tab-attrs__field"
              field="UiText"
              :label="$t('CORPORATE_WEB_SITE_LBL')"
              :placeholder="$t('CORPORATE_WEB_SITE_PH')"
              v-model="form.corporateWebSite"
              :errors="formErrors.corporateWebSite.map(jsvLocalizeError)"
              @input="formErrors.corporateWebSite = []"
              @blur="submit({ corporateWebSite: form.corporateWebSite })"
            />
          </ui-col>

          <ui-col
            class="user-card-tab-attrs__col"
            lg="6"
            xs="12"
          >
            <user-card-field
              class="user-card-tab-attrs__field"
              field="UiText"
              :label="$t('BRANCH_OFFICE_NAME_LBL')"
              :placeholder="$t('BRANCH_OFFICE_NAME_PH')"
              v-model="form.branchOfficeName"
              :errors="formErrors.branchOfficeName.map(jsvLocalizeError)"
              @input="formErrors.branchOfficeName = []"
              @blur="submit({ branchOfficeName: form.branchOfficeName })"
            />
          </ui-col>

          <ui-col
            class="user-card-tab-attrs__col"
            lg="6"
            xs="12"
          >
            <user-card-field
              class="user-card-tab-attrs__field"
              field="UiPhone"
              :label="$t('OFFICE_PHONE_LBL')"
              v-model="form.officePhone"
              :errors="formErrors.officePhone.map(jsvLocalizeError)"
              @input="formErrors.officePhone = []"
              @blur="submit({ officePhone: form.officePhone })"
            />
          </ui-col>

          <ui-col
            class="user-card-tab-attrs__col"
            lg="6"
            xs="12"
          >
            <user-card-field
              class="user-card-tab-attrs__field"
              field="UiText"
              :label="$t('SECONDHAND_DEAL_PERMISSION_NUMBER_LBL')"
              :placeholder="$t('SECONDHAND_DEAL_PERMISSION_NUMBER_PH')"
              v-model="form.secondhandDealPermissionNumber"
              :errors="formErrors.secondhandDealPermissionNumber
                .map(jsvLocalizeError)
              "
              @input="formErrors.secondhandDealPermissionNumber = []"
              @blur="submit({ secondhandDealPermissionNumber:
                form.secondhandDealPermissionNumber
              })"
              maxlength="12"
            />
          </ui-col>

          <ui-col
            class="user-card-tab-attrs__col"
            lg="6"
            xs="12"
          >
            <user-card-field
              class="user-card-tab-attrs__field"
              field="UiText"
              :label="$t('STATE_LBL')"
              :placeholder="$t('STATE_PH')"
              v-model="form.state"
              :errors="formErrors.state.map(jsvLocalizeError)"
              @input="formErrors.state = []"
              @blur="submit({ state: form.state })"
            />
          </ui-col>

          <ui-col
            class="user-card-tab-attrs__col"
            lg="6"
            xs="12"
          >
            <user-card-field
              class="user-card-tab-attrs__field"
              field="UiText"
              :label="$t('PREFECTURE_LBL')"
              :placeholder="$t('PREFECTURE_PH')"
              v-model="form.prefecture"
              :errors="formErrors.prefecture.map(jsvLocalizeError)"
              @input="formErrors.prefecture = []"
              @blur="submit({ prefecture: form.prefecture })"
            />
          </ui-col>
        </ui-row>
      </template>
    </form>
  </div>
</template>

<script>
import { UiRow, UiCol, UiIcon, UiSpinner } from '@shelf.network/ui-kit'
import UserCardField from './UserCardField'

import { mapGetters } from 'vuex'
import { userGetters } from 'Store/entities/User/types'

import { ACCOUNT_TYPE_TO_USERS_TAB_MAP } from 'Components/Users/constants'
import { USER_CLAIMS } from 'Constants/userClaims'

import { VerifyUserEmail } from '../../commands/VerifyUserEmail'
import { UpdateUser } from '../../commands/UpdateUser'

import { jsv } from 'Utils/jsv'
import validationSchema from '../../json-schemas/update-attrs-validation.json'

import { jsvMimicryError } from 'Utils/jsvMimicryError'
import { jsvLocalizeError } from 'Utils/jsvLocalizeError'

import { can } from 'Utils/userHelpers'
import { Location } from 'Models/Location'
import { UserEntry } from 'Models/UserEntry'

import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'

export default {
  name: 'user-card-tab-attrs',
  components: {
    UiRow,
    UiCol,
    UiIcon,
    UiSpinner,
    UserCardField,
  },

  props: {
    userEntry: {
      type: UserEntry,
      required: true
    }
  },

  data () {
    return {
      form: this.entryToForm(),
      formErrors: this.entryToFormErrorsDefault(),
      isProcessingVerifyEmail: false,
    }
  },

  computed: {
    ...mapGetters('entities/user', {
      isAdmin: userGetters.IS_ADMIN,
    }),

    isAccountTypeSelectDisabled () {
      return [
        UserEntry.accountTypesEnum.broker,
        UserEntry.accountTypesEnum.dealer
      ].includes(this.userEntry.accountType)
    }
  },

  watch: {
    userEntry: {
      handler (value) {
        Object.assign(this.form, this.entryToForm())
      },
    }
  },

  methods: {
    entryToForm () {
      return {
        accountType: this.userEntry.accountType,
        email: this.userEntry.email,
        location: this.userEntry.location.toString(),
        ...(
          can(USER_CLAIMS.SHELF_AP_FEATURES)
            ? {
              company: this.userEntry.company,
              corporateWebSite: this.userEntry.corporateWebSite,
              branchOfficeName: this.userEntry.branchOfficeName,
              officePhone: this.userEntry.officePhone,
              secondhandDealPermissionNumber:
                this.userEntry.secondhandDealPermissionNumber,
              state: this.userEntry.state,
              prefecture: this.userEntry.prefecture,
            } : {}
        ),
      }
    },

    entryToFormErrorsDefault () {
      const newObjEntries = Object.entries(this.entryToForm())
        .map(([key]) => [key, []])
      return Object.fromEntries(newObjEntries)
    },

    checkChanged (form) {
      const oldForm = this.entryToForm()
      const diff = pickBy(form, (val, key) => val !== oldForm[key])
      return Object.keys(diff).length > 0
    },

    checkFormValid (form) {
      if (Object.values(this.formErrors).some(el => el.length > 0)) return false

      const result = jsv.validate(validationSchema, form)
      if (result.isValid) return true

      const formErrors = pick(result.byField(), Object.keys(form))
      if (!Object.keys(formErrors).length) return true

      Object.assign(this.formErrors, formErrors)
      return false
    },

    async verifyEmail () {
      if (this.isProcessingVerifyEmail) return
      this.isProcessingVerifyEmail = true
      const cmd = new VerifyUserEmail({ userEntry: this.userEntry })
      await cmd.execute()
      this.isProcessingVerifyEmail = false
    },

    submitLocation () {
      this.submit({ location: this.form.location })
    },

    async submitAccountType () {
      await this.submit({
        accountType: this.form.accountType,
        accountTypeStr: this.form.accountType
      })

      this.$router.push({
        name: 'users',
        params: { tab: ACCOUNT_TYPE_TO_USERS_TAB_MAP[this.form.accountType] },
        query: this.$route.query,
      })
    },

    async submit (form) {
      if (!this.checkChanged(form) || !this.checkFormValid(form)) return

      const attributes = { ...form }
      if (attributes.hasOwnProperty('email')) {
        attributes.email = attributes.email.toLowerCase()
        attributes.emailVerified = false
      }
      if (attributes.hasOwnProperty('secondhandDealPermissionNumber')) {
        const key = 'secondhandDealPermissionNumber'
        attributes[key] = attributes[key].toUpperCase()
      }
      if (attributes.hasOwnProperty('location')) {
        const locationSplit = Location.fromString(attributes.location)
        attributes.countryId = Number(locationSplit.countryId)
        attributes.region = String(locationSplit.regionId)
        attributes.cityId = Number(locationSplit.cityId)
        delete attributes.location
      }
      const cmd = new UpdateUser({
        isForcedErrorThrow: true,
        userEntry: this.userEntry,
        attributes,
      })

      try {
        await cmd.execute()
      } catch (error) {
        if (error.httpStatus === 409) {
          const responseErrors = error.originalError.response.data.errors
          if (responseErrors.find(el => el.code === 'email')) {
            this.formErrors.email.push(jsvMimicryError(this.$t('EMAIL_CONFLICT_ERR')))
          }
        }
      }
    },

    jsvLocalizeError,
  }
}
</script>

<style lang="scss" scoped>
.user-card-tab-attrs {
  &__col {
    margin-top: 2em;
    margin-bottom: 1em;
  }

  &__check-ico-docked {
    color: $color-flag-is-success;
    font-size: 2em;
    display: grid;
    height: inherit;
    width: inherit;
    align-items: center;
    justify-content: end;
  }

  &__verify-email-btn {
    color: $color-sys-info;
    background: none;
    border: none;
    position: relative;
    padding: 0 0.5em;
    margin-left: auto;
    display: block;

    &:hover {
      color: mix($color-sys-info, $color-ui-default, 80);
    }
  }
}
</style>

<i18n>
{
  "en": {
    "ACCOUNT_TYPE_LBL": "ACCOUNT TYPE",
    "EMAIL_LBL": "EMAIL",
    "EMAIL_PH": "Enter an email",
    "EMAIL_CONFLICT_ERR": "Email is already in use",
    "VERIFY_EMAIL_BTN": "Verify",
    "VERIFIED_EMAIL_HINT": "Email verified",
    "LOCATION_LBL": "LOCATION",
    "COMPANY_LBL": "COMPANY",
    "COMPANY_PH": "Enter a company name",
    "CORPORATE_WEB_SITE_LBL": "CORPORATE WEB SITE",
    "CORPORATE_WEB_SITE_PH": "Enter a website",
    "BRANCH_OFFICE_NAME_LBL": "BRANCH OFFICE NAME",
    "BRANCH_OFFICE_NAME_PH": "Enter a branch office name",
    "OFFICE_PHONE_LBL": "OFFICE PHONE",
    "SECONDHAND_DEAL_PERMISSION_NUMBER_LBL": "SECONDHAND DEAL PERMISSION NUMBER",
    "SECONDHAND_DEAL_PERMISSION_NUMBER_PH": "Enter a number",
    "STATE_LBL": "STATE",
    "STATE_PH": "Enter a state",
    "PREFECTURE_LBL": "PREFECTURE",
    "PREFECTURE_PH": "Enter a prefecture"
  },
  "ka": {
    "ACCOUNT_TYPE_LBL": "ᲢᲘᲞᲘ",
    "EMAIL_LBL": "ᲔᲚ.ᲤᲝᲡᲢᲐ",
    "EMAIL_PH": "შეიყვანე ელ.ფოსტა",
    "EMAIL_CONFLICT_ERR": "ელ.ფოსტრა უკვე რეგისტრირებულია",
    "VERIFY_EMAIL_BTN": "ვერიფიკაცია",
    "VERIFIED_EMAIL_HINT": "ელ.ფოსტრა დადასტურდა",
    "LOCATION_LBL": "ᲚᲝᲙᲐᲪᲘᲐ",
    "COMPANY_LBL": "ᲙᲝᲛᲞᲐᲜᲘᲐ",
    "COMPANY_PH": "კომპანიის დასახელება",
    "CORPORATE_WEB_SITE_LBL": "ᲕᲔᲑ ᲒᲕᲔᲠᲓᲘ",
    "CORPORATE_WEB_SITE_PH": "შეიყვანეთ ვებ გვერდი",
    "BRANCH_OFFICE_NAME_LBL": "ᲝᲤᲘᲡᲘᲡ ᲓᲐᲡᲐᲮᲔᲚᲔᲑᲐ",
    "BRANCH_OFFICE_NAME_PH": "შეიყვანე ოფისის დასახელება",
    "OFFICE_PHONE_LBL": "ᲝᲤᲘᲡᲘᲡ ᲢᲔᲚᲔᲤᲝᲜᲘ",
    "SECONDHAND_DEAL_PERMISSION_NUMBER_LBL": "ᲚᲘᲪᲔᲜᲖᲘᲘᲡ ᲜᲝᲛᲔᲠᲘ",
    "SECONDHAND_DEAL_PERMISSION_NUMBER_PH": "შეიყვანეთ ნომერი",
    "STATE_LBL": "ᲠᲔᲒᲘᲝᲜᲘ",
    "STATE_PH": "შეიყვანე რეგიონი",
    "PREFECTURE_LBL": "ᲞᲠᲔᲤᲔᲥᲢᲣᲠᲐ",
    "PREFECTURE_PH": "შეიყვანე პრეფექტურა"
  },
  "ru": {
    "ACCOUNT_TYPE_LBL": "ТИП УЧЕТНОЙ ЗАПИСИ",
    "EMAIL_LBL": "ЭЛЕКТРОННАЯ ПОЧТА",
    "EMAIL_PH": "Введите почту",
    "EMAIL_CONFLICT_ERR": "Почта уже используется",
    "VERIFY_EMAIL_BTN": "Подтвердить",
    "VERIFIED_EMAIL_HINT": "Почта подтверждена",
    "LOCATION_LBL": "ЛОКАЦИЯ",
    "COMPANY_LBL": "КОМПАНИЯ",
    "COMPANY_PH": "ВВедите компанию",
    "CORPORATE_WEB_SITE_LBL": "КОРПОРАТИВНЫЙ САЙТ",
    "CORPORATE_WEB_SITE_PH": "Введите адрес сайта",
    "BRANCH_OFFICE_NAME_LBL": "ИМЯ ФИЛИАЛА",
    "BRANCH_OFFICE_NAME_PH": "Введите имя филиала",
    "OFFICE_PHONE_LBL": "ТЕЛЕФОН ОФИСА",
    "SECONDHAND_DEAL_PERMISSION_NUMBER_LBL": "НОМЕР ЛИЦЕНЗИИ",
    "SECONDHAND_DEAL_PERMISSION_NUMBER_PH": "Введите номер",
    "STATE_LBL": "ШТАТ",
    "STATE_PH": "Введите штат",
    "PREFECTURE_LBL": "ПРЕФЕКТУРА",
    "PREFECTURE_PH": "Введите префектуру"
  },
  "uk": {
    "ACCOUNT_TYPE_LBL": "ТИП ОБЛІКОВОГО ЗАПИСУ",
    "EMAIL_LBL": "ЕЛЕКТРОННА ПОШТА",
    "EMAIL_PH": "Введіть пошту",
    "EMAIL_CONFLICT_ERR": "Пошта вше використовується",
    "VERIFY_EMAIL_BTN": "Підтвердити",
    "VERIFIED_EMAIL_HINT": "Пошту підтверджено",
    "LOCATION_LBL": "ЛОКАЦІЯ",
    "COMPANY_LBL": "КОМПАНІЯ",
    "COMPANY_PH": "Введіть ім’я компанії",
    "CORPORATE_WEB_SITE_LBL": "КОРПОРАТИВНИЙ САЙТ",
    "CORPORATE_WEB_SITE_PH": "Введіть адрес сайту",
    "BRANCH_OFFICE_NAME_LBL": "ІМ’Я ФІЛІЇ",
    "BRANCH_OFFICE_NAME_PH": "Введіть ім’я філії",
    "OFFICE_PHONE_LBL": "ТЕЛЕФОН ОФІСУ",
    "SECONDHAND_DEAL_PERMISSION_NUMBER_LBL": "НОМЕР ЛІЦЕНЗІЇ",
    "SECONDHAND_DEAL_PERMISSION_NUMBER_PH": "Введіть номер",
    "STATE_LBL": "ШТАТ",
    "STATE_PH": "Введіть штат",
    "PREFECTURE_LBL": "ПРЕФЕКТУРА",
    "PREFECTURE_PH": "Введіть префектуру"
  }
}
</i18n>
