import { UserCommand } from './UserCommand'
import { sdk } from 'Services/shelfNetworkSdk'

export class DeleteUser extends UserCommand {
  async execute () {
    try {
      await sdk.horizon.account.delete(this._userEntry.id)

      this._removeStoredItem(this._userEntry.id)

      const msg = this.$t('CMD.USERS.REMOVE.SUCCESS_MSG', { name: this._getName() })
      this._reportSuccess(msg)
    } catch (error) {
      const msg = this.$t('CMD.USERS.REMOVE.FAILURE_MSG', { name: this._getName() })
      this._reportError(error, msg)
    }
  }
}
