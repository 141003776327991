import { UserCommand } from './UserCommand'
import { sdk } from 'Services/shelfNetworkSdk'

/**
 * @typedef {object} UpdateUserArgsDef
 * @property {object} newPassword New attributes to apply to the user
 *
 * @typedef {import('./UserCommand').UserCommandArgs} UserCommandArgs
 * @typedef {UserCommandArgs & UpdateUserArgsDef} ChangeUserPwdArgs
 */

export class ChangeUserPwd extends UserCommand {
  /**
   * Create a configured UpdateUser command
   * @param {ChangeUserPwdArgs} opts
   */
  constructor (opts) {
    super(opts)

    this._newPassword = opts.newPassword
  }

  async execute () {
    try {
      await sdk.auth.changePassword(null, this._newPassword, this._userEntry.id)

      const msg = this.$t('CMD.USERS.UPDATE.SUCCESS_MSG', { name: this._getName() })
      this._reportSuccess(msg)
    } catch (error) {
      const msg = this.$t('CMD.USERS.UPDATE.FAILURE_MSG', { name: this._getName() })
      this._reportError(error, msg)
    }
  }
}
