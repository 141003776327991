<template>
  <button
    class="op-verify-phone user-op user-op__btn"
    @click="onClick"
  >
    <ui-icon
      class="user-op__btn-ico"
      icon="phone"
    />

    <span class="user-op__btn-txt">
      {{ $t('VERIFY_PHONE_BTN') }}
    </span>
  </button>
</template>

<script>
import { UiIcon } from '@shelf.network/ui-kit'
import { UserEntry } from 'Models/UserEntry'
import { VerifyUserPhone } from '../../commands/VerifyUserPhone'

export default {
  name: 'op-verify-phone',

  components: {
    UiIcon,
  },

  props: {
    userEntry: {
      type: UserEntry,
      required: true,
    },
  },

  methods: {
    async onClick () {
      const cmd = new VerifyUserPhone({ userEntry: this.userEntry })
      await cmd.execute()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../styles/users-operations.scss";
</style>

<i18n>
{
  "en": {
    "VERIFY_PHONE_BTN": "Verify phone"
  },
  "ka": {
    "VERIFY_PHONE_BTN": "ტელ. ვერიფიკაცია"
  },
  "ru": {
    "VERIFY_PHONE_BTN": "Подтвердить телефон"
  },
  "uk": {
    "VERIFY_PHONE_BTN": "Підтвердити телефон"
  }
}
</i18n>
