<template>
  <!-- eslint-disable vue/singleline-html-element-content-newline -->
  <div class="user-card-skeleton">
    <div class="user-card-skeleton__head">
      <div class="user-card-skeleton__head-avatar" />
      <div class="user-card-skeleton__head-user">
        <div class="user-card-skeleton__head-user-name">
          &nbsp;
        </div>
        <div class="user-card-skeleton__head-user-phone">
          &nbsp;
        </div>
      </div>
    </div>

    <div class="user-card-skeleton__subnav">
      <div class="user-card-skeleton__subnav-container">
        <div class="user-card-skeleton__subnav-tabs">
          <div class="user-card-skeleton__tab">&nbsp;</div>
          <div class="user-card-skeleton__tab">&nbsp;</div>
          <div class="user-card-skeleton__tab">&nbsp;</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'user-card-skeleton',
}
</script>

<style scoped lang="scss">
@import "@/styles/skeletons.scss";

.user-card-skeleton {
  &__head {
    display: flex;
    align-items: center;
    padding: 2em 2.75em;

    &-avatar {
      margin-right: 1em;
      position: relative;
      border-radius: 0.5em;
      width: 4em;
      min-width: 4em;
      height: 4em;

      @extend %skeleton-bg;
    }

    &-user {
      margin-right: auto;

      &-name {
        font-size: 1.2em;
        line-height: 1.2;
        width: 16ch;

        @extend %skeleton-bg;
      }

      &-phone {
        font-size: 1em;
        line-height: 1.2;
        width: 14ch;
        margin-top: 0.5em;

        @extend %skeleton-bg;
      }
    }
  }

  &__subnav {
    background-color: #f0f2f5;
    max-width: 100%;

    &-tabs {
      margin-right: auto;
      margin-left: 0;
      display: flex;
      align-items: center;
      height: 100%;
    }

    &-container {
      display: flex;
      height: 5em;
      align-items: center;
      padding: 0 2.75em;
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      width: 100%;

      @include scrollbar-invisible();
    }
  }

  &__tab {
    @extend %skeleton-bg;

    height: auto;
    line-height: 1;
    margin-right: 2em;
    width: 10ch;
  }

  @include respond-below(sm) {
    &__head {
      display: block;

      &-user {
        margin-right: 3.2em;
        display: flex;
        flex-direction: column;

        &-name {
          width: 16ch;
          max-width: 100%;
          font-size: 0.8em;
          margin-top: 0.5em;
        }

        &-phone {
          font-size: 0.8em;
          margin-top: 1.5em;
          width: 13ch;
          max-width: 100%;
        }
      }

      &-avatar {
        float: left;
      }

      &-due-date,
      &-status {
        width: 100%;
        margin: 2.5em 0;
      }
    }
  }
}
</style>
