<template>
  <ui-portal>
    <ui-modal
      class="user-edit-credentials-modal"
      :title="$t('EDIT_CREDENTIALS_TITLE')"
      :show-close-btn="!isSubmitting"
      @close="close"
    >
      <form
        class="user-edit-credentials-modal__form"
        :class="{ 'user-edit-credentials-modal__form_loading': isSubmitting }"
        @submit.prevent="submit()"
      >
        <div class="user-edit-credentials-modal__field">
          {{ $t('NEW_PASSWORD_LBL') }}
          <ui-text
            class="user-edit-credentials-modal__field-input"
            type="password"
            :placeholder="$t('NEW_PASSWORD_PH')"
            v-model="form.password"
            :is-error="formErrors.password.length > 0"
            @input="formErrors.password = []"
          >
            <span
              slot="error"
              v-for="err of formErrors.password"
              :key="err.keyword"
            >
              {{ jsvLocalizeError(err) }}
            </span>
          </ui-text>
        </div>

        <div class="user-edit-credentials-modal__field">
          {{ $t('NEW_PASSWORD_CONFIRM_LBL') }}
          <ui-text
            class="user-edit-credentials-modal__field-input"
            type="password"
            :placeholder="$t('NEW_PASSWORD_CONFIRM_PH')"
            v-model="form.passwordConfirm"
            :is-error="formErrors.passwordConfirm.length > 0"
            @input="formErrors.passwordConfirm = []"
          >
            <span
              slot="error"
              v-for="err of formErrors.passwordConfirm"
              :key="err.keyword"
            >
              {{ jsvLocalizeError(err) }}
            </span>
          </ui-text>
        </div>

        <div class="user-edit-credentials-modal__buttons">
          <ui-button
            type="submit"
            class="user-edit-credentials-modal__btn"
          >
            {{ $t('CHANGE_PWD_BTN') }}
          </ui-button>

          <ui-button
            @click="close"
            type="button"
            class="user-edit-credentials-modal__btn"
            look="secondary"
            fill="frame-hover"
          >
            {{ $t('CANCEL_BTN') }}
          </ui-button>
        </div>
      </form>

      <ui-spinner
        class="user-edit-credentials-modal__loader"
        overlay
        type="pills"
        :show="isSubmitting"
      />
    </ui-modal>
  </ui-portal>
</template>

<script>
import {
  UiButton,
  UiText,
  UiSpinner,
  UiModal,
  UiPortal,
} from '@shelf.network/ui-kit'
import { UserEntry } from 'Models/UserEntry'
import { jsv } from 'Utils/jsv'
import validationSchema from '../../json-schemas/update-pwd-validation.json'
import { ChangeUserPwd } from '../../commands/ChangeUserPwd'
import { scrollToErrorInModal } from 'Utils/scrollTo'
import { jsvLocalizeError } from 'Utils/jsvLocalizeError'

export default {
  name: 'user-edit-credentials-modal',

  components: {
    UiButton,
    UiText,
    UiSpinner,
    UiModal,
    UiPortal,
  },

  props: {
    userEntry: {
      type: UserEntry,
      required: true,
    },
  },

  data () {
    return {
      form: {
        password: '',
        passwordConfirm: '',
      },
      formErrors: {
        password: [],
        passwordConfirm: [],
      },
      isSubmitting: false,
    }
  },

  methods: {
    checkFormValid () {
      if (Object.values(this.formErrors).some(el => el.length > 0)) return false

      const result = jsv.validate(validationSchema, this.form)
      if (result.isValid) return true

      Object.assign(this.formErrors, result.byField())
      return false
    },

    async submit () {
      if (!this.checkFormValid()) {
        scrollToErrorInModal()
        return
      }

      this.isSubmitting = true
      const cmd = new ChangeUserPwd({
        userEntry: this.userEntry,
        newPassword: this.form.password,
        isForcedErrorThrow: true,
      })
      await cmd.execute()
      this.isSubmitting = false
    },

    close () {
      this.$emit('close')
    },

    jsvLocalizeError,
  },
}
</script>

<style scoped lang="scss">
.user-edit-credentials-modal {
  &__form {
    max-width: 100%;
    width: 33em;
    padding: 2em 0.5em;

    &_loading {
      filter: blur(0.2em);
    }
  }

  &__field {
    margin-bottom: 2em;

    &-input {
      margin-top: 0.4em;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 5em;
    padding-top: 2em;
    border-top: 1px solid $color-light-grey;

    @include respond-below(sm) {
      flex-direction: column;
    }
  }

  &__btn {
    margin: 1.2em 0;
    min-width: 15em;

    @include respond-below(sm) {
      min-width: 0;
      margin: 1em 0;
    }
  }

  & /deep/ .modal__content {
    max-width: 36em;

    @include respond-below(sm) {
      max-width: unset;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "CHANGE_PWD_BTN": "Change password",
    "EDIT_CREDENTIALS_TITLE": "Password change",
    "CANCEL_BTN": "Cancel",
    "NEW_PASSWORD_LBL": "NEW PASSWORD",
    "NEW_PASSWORD_PH": "Pick a password",
    "NEW_PASSWORD_CONFIRM_LBL": "PASSWORD AGAIN",
    "NEW_PASSWORD_CONFIRM_PH": "Repeat the password"
  },
  "ka": {
    "CHANGE_PWD_BTN": "პაროლის ცვლილება",
    "EDIT_CREDENTIALS_TITLE": "პაროლის ცვლილება",
    "CANCEL_BTN": "დახურვა",
    "NEW_PASSWORD_LBL": "ᲐᲮᲐᲚᲘ ᲞᲐᲠᲝᲚᲘ",
    "NEW_PASSWORD_PH": "ჩაწერე პაროლი",
    "NEW_PASSWORD_CONFIRM_LBL": "ᲒᲐᲘᲛᲔᲝᲠᲔ ᲞᲐᲠᲝᲚᲘ",
    "NEW_PASSWORD_CONFIRM_PH": "გაიმეორე პაროლი"
  },
  "ru": {
    "CHANGE_PWD_BTN": "Сменить пароль",
    "EDIT_CREDENTIALS_TITLE": "Смена пароля",
    "CANCEL_BTN": "Закрыть",
    "NEW_PASSWORD_LBL": "НОВЫЙ ПАРОЛЬ",
    "NEW_PASSWORD_PH": "Придумайте пароль",
    "NEW_PASSWORD_CONFIRM_LBL": "ПАРОЛЬ ПОВТОРНО",
    "NEW_PASSWORD_CONFIRM_PH": "Повторите пароль"
  },
  "uk": {
    "CHANGE_PWD_BTN": "Змінити пароль",
    "EDIT_CREDENTIALS_TITLE": "Зміна пароля",
    "CANCEL_BTN": "Закрити",
    "NEW_PASSWORD_LBL": "НОВИЙ ПАРОЛЬ",
    "NEW_PASSWORD_PH": "Придумайте пароль",
    "NEW_PASSWORD_CONFIRM_LBL": "ПАРОЛЬ ПОВТОРНО",
    "NEW_PASSWORD_CONFIRM_PH": "Повторіть пароль"
  }
}
</i18n>
