<template>
  <div class="user-item">
    <div class="users-cmn__row">
      <user-cell-name-ava
        class="user-item__cell"
        :user-entry="item"
      />

      <user-cell-location
        class="user-item__cell"
        :location="item.location"
      />

      <user-cell-phone
        class="user-item__cell"
        :phone="item.phone"
      />

      <user-cell-email
        class="user-item__cell"
        :email="item.email"
      />

      <user-cell-time-ago
        class="user-item__cell"
        :date="item.createdAt"
      />

      <user-cell-operations
        class="user-item__cell user-item__cell_end"
        :user-entry="item"
      />
    </div>
  </div>
</template>

<script>
import { USERS_LIST_TEMPLATES } from '../constants'
import { UserEntry } from 'Models/UserEntry'
import UserCellNameAva from './UserCellNameAva'
import UserCellLocation from './UserCellLocation'
import UserCellPhone from './UserCellPhone'
import UserCellEmail from './UserCellEmail'
import UserCellTimeAgo from './UserCellTimeAgo'
import UserCellOperations from './UserCellOperations'

export default {
  name: 'user-item',

  components: {
    UserCellNameAva,
    UserCellLocation,
    UserCellPhone,
    UserCellEmail,
    UserCellTimeAgo,
    UserCellOperations,
  },

  props: {
    template: {
      type: String,
      default: USERS_LIST_TEMPLATES.regular,
      validator (value) {
        return Object.values(USERS_LIST_TEMPLATES).includes(value)
      },
    },

    item: {
      type: UserEntry,
      required: true,
    },
  },

  data () {
    return {
      USERS_LIST_TEMPLATES,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/users";

.user-item {
  padding: 2.1em 0;
  border-bottom: 1px solid rgba($color-grey, 0.5);

  &__cell {
    &_end {
      justify-self: end;
    }
  }
}
</style>
