import { Command } from '@/commands/Command'
import { UserEntry } from 'Models/UserEntry'
import { safeUserName } from 'Utils/safeUserName'
import { rootGet, rootDispatch } from 'Store/helpers/rootHelpers'
import { uiUsersGetters, uiUsersActions } from '../store/types'

/**
 * @typedef {object} UserCommandArgsDef
 * @property {UserEntry} userEntry User entry to operate over
 *
 * @typedef {import('@/commands/Command').CommandArgs} CommandArgs
 * @typedef {CommandArgs & UserCommandArgsDef} UserCommandArgs
 */

export class UserCommand extends Command {
  /**
   * Create a configured UserCommand command
   * @param {UserCommandArgs} opts
   */
  constructor (opts) {
    super(opts)

    if (!(opts.userEntry instanceof UserEntry)) {
      throw TypeError('userEntry should be an instance of UserEntry')
    }

    this._userEntry = opts.userEntry
  }

  _getName () {
    return safeUserName(this._userEntry.firstName)
  }

  _getStoredItem () {
    const list = rootGet(`ui/users/${uiUsersGetters.LIST_RAW}`)
    const index = list.findIndex(el => el.id === this._userEntry.id)

    return {
      index,
      item: {
        ...list[index],
        // HACK: PATCH horizon/account accepts string account type
        // instead of integer one, so we need to keep string value
        // to maintain undo action
        accountType: list[index].accountTypeStr
      },
    }
  }

  _removeStoredItem (id) {
    return rootDispatch(`ui/users/${uiUsersActions.REMOVE_ITEM}`, id)
  }

  _updateStoredItem (opts) {
    const { item } = this._getStoredItem()
    const updated = Object.assign(item, opts)
    return rootDispatch(`ui/users/${uiUsersActions.UPDATE_ITEM}`, updated)
  }
}
