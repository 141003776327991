<template>
  <button
    class="op-make-pro user-op user-op__btn"
    @click="onClick"
  >
    <icon-combo
      class="op-make-pro__icon-combo user-op__btn-ico"
      icon="user"
      icon-addition="plus-bold"
    />

    <span class="user-op__btn-txt">
      <template v-if="isRegular">
        {{ $t('PROMOTE_TO_PRO_BTN') }}
      </template>

      <template v-else>
        {{ $t('PROMOTE_TO_BROKER_BTN') }}
      </template>
    </span>
  </button>
</template>

<script>
import IconCombo from 'Common/IconCombo'
import { UserEntry } from 'Models/UserEntry'

export default {
  name: 'op-make-pro',

  components: {
    IconCombo,
  },

  props: {
    userEntry: {
      type: UserEntry,
      required: true,
    },
  },

  computed: {
    isRegular () {
      return this.userEntry.accountType === UserEntry.accountTypesEnum.user
    },
  },

  methods: {
    onClick () {
      if (this.isRegular) {
        alert('TODO: promote to pro action')
      } else {
        alert('TODO: promote to broker action')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../styles/users-operations.scss";

.op-make-pro {
  &__icon-combo {
    --addition-color: #{$color-flag-is-success};
  }
}
</style>

<i18n>
{
  "en": {
    "PROMOTE_TO_PRO_BTN": "Make user PRO",
    "PROMOTE_TO_BROKER_BTN": "Make user a broker"
  },
  "ka": {
    "PROMOTE_TO_PRO_BTN": "გახადე PRO",
    "PROMOTE_TO_BROKER_BTN": "გახადე ბროკერი"
  },
  "ru": {
    "PROMOTE_TO_PRO_BTN": "Сделать PRO",
    "PROMOTE_TO_BROKER_BTN": "Сделать брокером"
  },
  "uk": {
    "PROMOTE_TO_PRO_BTN": "Зробити PRO",
    "PROMOTE_TO_BROKER_BTN": "Зробити брокером"
  }
}
</i18n>
