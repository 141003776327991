<template>
  <ui-portal>
    <div
      class="user-card-modal"
      :class="'user-card-modal_' + (isAnimated ? 'animate' : 'before-animate')"
      :style="{ '--user-animation': 'appear-animation-' + animationDirection }"
      v-if="isOpen"
    >
      <button
        class="user-card-modal__close-btn"
        @click="$emit('close')"
      >
        <ui-icon icon="close" />
      </button>

      <div class="user-card-modal__pager user-card-modal__pager_prev">
        <button
          class="user-card-modal__pager-link"
          v-if="prevUser.id"
          @click="goPrevUser(prevUser.id)"
        >
          <ui-icon
            icon="arrow-left"
            class="user-card-modal__pager-icon"
          />
          <span class="user-card-modal__pager-name">
            {{ prevUserFullName }}
          </span>
        </button>
      </div>

      <div class="user-card-modal__pager user-card-modal__pager_next">
        <button
          class="user-card-modal__pager-link"
          v-if="nextUser.id"
          @click="goNextUser(nextUser.id)"
        >
          <span class="user-card-modal__pager-name">
            {{ nextUserFullName }}
          </span>
          <ui-icon
            icon="arrow-right"
            class="user-card-modal__pager-icon"
          />
        </button>
      </div>

      <div class="user-card-modal__content-wrap">
        <template v-if="currentUser.id">
          <user-card
            :user-entry="currentUser"
            :user-tab="userTab"
            @update:userTab="$emit('update:userTab', $event)"
          />
        </template>

        <template v-else-if="isLoading">
          <user-card-skeleton />
        </template>

        <template v-else-if="isNotFoundCurrentUser">
          <p class="user-card-modal__not-found-err">
            {{ $t('USER_LOAD_FAILED_MSG') }}
          </p>
        </template>
      </div>
    </div>
  </ui-portal>
</template>

<script>
import { UiIcon, UiPortal, BodyScrollPreventer } from '@shelf.network/ui-kit'
import UserCard from './UserCard'
import UserCardSkeleton from './UserCardSkeleton'
import { validateArrayOf } from 'Models/modelUtils'
import { UserEntry } from 'Models/UserEntry'
import { mapActions } from 'vuex'
import { uiUsersActions, uiUsersMutations } from '../../store/types'
import { wait } from 'Utils/wait'
import { safeUserName } from 'Utils/safeUserName'
import formatFullName from 'Utils/formatFullName'

export default {
  name: 'user-card-modal',
  components: {
    UiIcon,
    UiPortal,
    UserCard,
    UserCardSkeleton,
  },

  props: {
    usersList: {
      type: Array,
      validator: validateArrayOf(UserEntry),
      default: () => ([]),
    },

    isUsersLoading: {
      type: Boolean,
      default: false,
    },

    userId: {
      type: String,
      default: '',
    },

    userTab: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      isLoadingCurrentUser: false,
      isNotFoundCurrentUser: false,
      isAnimated: true,
      animationDirection: 'up',
      storeUnsubscriber: () => { },
    }
  },

  computed: {

    currentUser () {
      return this.usersList.find(el => el.id === this.userId) || {}
    },

    isOpen () {
      return Boolean(this.userId)
    },

    isLoading () {
      return this.isUsersLoading || this.isLoadingCurrentUser
    },

    prevUser () {
      if (!this.currentUser.id) return {}
      const index = this.usersList.findIndex(l => l.id === this.currentUser.id)
      if (index < 0) return {}
      return this.usersList[index - 1] || {}
    },

    nextUser () {
      if (!this.currentUser.id) return {}
      const index = this.usersList.findIndex(l => l.id === this.currentUser.id)
      if (index < 0) return {}
      return this.usersList[index + 1] || {}
    },

    prevUserFullName () {
      return safeUserName(formatFullName(this.prevUser))
    },

    nextUserFullName () {
      return safeUserName(formatFullName(this.nextUser))
    },
  },

  watch: {
    isOpen: {
      immediate: true,
      handler (newV, oldV) {
        if (newV && !oldV) this.animationDirection = 'up'
      }
    }
  },

  created () {
    this.$watch(
      () => ([this.userId, this.usersList, this.isUsersLoading]),
      async () => {
        if (this.userId && !this.isLoading && !this.currentUser.id) {
          await this.loadCurrentUser()
        }
      },
      { immediate: true }
    )

    this.$watch(
      'isOpen',
      (newV, oldV) => newV
        ? BodyScrollPreventer.on()
        : oldV && BodyScrollPreventer.off(),
      { immediate: true },
    )

    this.storeUnsubscriber = this.$store.subscribe(async (mutation, state) => {
      let id
      switch (mutation.type) {
        case `ui/users/${uiUsersMutations.UPDATE_ITEM}`:
          id = mutation.payload.id; break

        case `ui/users/${uiUsersMutations.REMOVE_ITEM}`:
          id = mutation.payload; break

        default:
          return
      }

      if (!this.userId || this.userId !== id || !this.usersList.length) return

      const nextUserId = this.nextUser.id
      const prevUserId = this.prevUser.id
      const isWasPresent = this.usersList.find(el => el.id === this.userId)
      await this.$nextTick()
      const isNowPresent = this.usersList.find(el => el.id === this.userId)

      if (isWasPresent && !isNowPresent) {
        if (nextUserId) {
          this.goNextUser(nextUserId)
        } else if (prevUserId) {
          this.goPrevUser(prevUserId)
        } else {
          this.$emit('close')
        }
      }
    })
  },

  beforeDestroy () {
    if (this.isOpen) BodyScrollPreventer.off()
    this.storeUnsubscriber()
  },

  methods: {
    ...mapActions('ui/users', {
      loadUserAction: uiUsersActions.LOAD_ITEM,
    }),

    async animate (direction) {
      this.isAnimated = false

      // wait for vdom -> dom
      await this.$nextTick()

      // and then wait a bit more (hack)
      await wait(40)

      this.isAnimated = true
      this.animationDirection = direction
    },

    goNextUser (id = '') {
      this.animate('forward')
      this.$emit('update:userId', id || this.nextUser.id)
      // TODO: load more
    },

    goPrevUser (id = '') {
      this.animate('backward')
      this.$emit('update:userId', id || this.prevUser.id)
    },

    async loadCurrentUser () {
      this.isLoadingCurrentUser = true
      await this.loadUser(this.userId)
      if (!this.currentUser.id) {
        this.isNotFoundCurrentUser = true
      }
      this.isLoadingCurrentUser = false
    },

    async loadUser (userId) {
      try {
        await this.loadUserAction({ userId })
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>

<style lang="scss">
@keyframes appear-animation-backward {
  from {
    opacity: 0;
    transform: translateX(-3em);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes appear-animation-forward {
  from {
    opacity: 0;
    transform: translateX(3em);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes appear-animation-up {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>

<style scoped lang="scss">
.user-card-modal {
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @include scrollbar-invisible();

  &_before-animate /deep/ {
    .user-card__head,
    .user-card__subnav-container,
    .user-card__tab-component,
    .user-card__comment-comp,
    .user-card__footer-row {
      opacity: 0;
    }
  }

  &_animate /deep/ {
    .user-card__head,
    .user-card__subnav-container,
    .user-card__tab-component,
    .user-card__comment-comp,
    .user-card__footer-row {
      animation-name: var(--user-animation, appear-animation-up);
      animation-duration: 0.6s;
      animation-iteration-count: 1;
    }
  }

  &__content-wrap {
    min-height: 30vh;
    width: 60%;
    max-width: 70em;
    background: #fff;
    margin: 7em auto;
    border-radius: $layout-border-radius;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__pager {
    width: 15%;
    height: 10%;
    position: fixed;
    top: 45%;
    min-height: 5em;

    &-link {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      height: 100%;
      width: 100%;
      color: $color-dark-grey;
      text-decoration: none;
      background: none;
      border: none;

      &:hover {
        color: $color-light;
      }
    }

    &-name {
      font-size: 1.2em;
      display: block;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-link:hover &-name {
      text-decoration: underline;
    }

    &-icon {
      font-size: 2em;
      padding: 0 1em;
      display: block;
    }

    &_next {
      text-align: right;
      right: 1em;
    }

    &_prev {
      text-align: left;
      left: 1em;
    }
  }

  &__close-btn {
    position: fixed;
    color: $color-dark-grey;
    top: 1em;
    bottom: 1em;
    left: 1em;
    box-sizing: border-box;
    font-size: 3em;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    width: calc(100vw - 2em);
    background: none;
    border: none;

    &:hover {
      color: $color-light;
    }
  }

  &__not-found-err {
    color: $color-flag-is-error;
    text-align: center;
    padding: 1em;
    height: 100%;
    margin: auto;
    font-weight: 700;
  }

  @include respond-below(sm) {
    padding: 0;
    border-radius: 0;
    display: block;

    &__pager {
      display: none;
    }

    &__content-wrap {
      width: 100%;
      min-height: 100vh;
      border-radius: 0;
      margin: 0;
    }

    &__close-btn {
      font-size: 2em;
      width: calc(100vw - 1em);
      position: absolute;
      bottom: auto;
      left: auto;
      z-index: z-index(1);
    }
  }
}
</style>

<i18n>
{
  "en": {
    "USER_LOAD_FAILED_MSG": "Could not load the provided user. Please ensure you are using the valid link or contact the system owner."
  },
  "ka": {
    "USER_LOAD_FAILED_MSG": "მომხმარებელი ვერ ჩაიტვირთა. დარწმუნდი რომ ინფორმაცია სწორია, ან დაუკავშირდი ადმინისტრატორს."
  },
  "ru": {
    "USER_LOAD_FAILED_MSG": "Не удалось загрузить пользователя. Повторите попытку позже или свяжитесь с владельцем системы."
  },
  "uk": {
    "USER_LOAD_FAILED_MSG": "Не вдалось завантажити користувача. Повторіть спробу пізніше або зв’яжіться з власником системи."
  }
}
</i18n>
