<template>
  <ui-select
    class="select-account-type"
    fill="frame"
    :options="options"
    :placeholder="placeholder || $t('COMMON.SELECT_FIELD.SELECT_ITEM_PH')"
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
    <slot
      name="error"
      slot="error"
    />
    <slot
      name="dock-left"
      slot="dock-left"
    />
  </ui-select>
</template>

<script>
import { UiSelect } from '@shelf.network/ui-kit'
import { UserEntry } from 'Models/UserEntry'

export default {
  name: 'select-account-type',
  components: { UiSelect },

  props: {
    value: { type: String, default: '' },
    placeholder: { type: String, default: '' },
  },

  computed: {
    options () {
      return [
        {
          label: this.$t('REGULAR_USER_ACC_TYPE'),
          value: UserEntry.accountTypesEnum.user,
        },
        {
          label: this.$t('PRO_USER_ACC_TYPE'),
          value: UserEntry.accountTypesEnum.business,
        },
        {
          label: this.$t('BROKER_ACC_TYPE'),
          value: UserEntry.accountTypesEnum.broker,
        },
        {
          label: this.$t('DEALER_ACC_TYPE'),
          value: UserEntry.accountTypesEnum.dealer,
        },
      ]
    },
  },
}
</script>

<i18n>
{
  "en": {
    "REGULAR_USER_ACC_TYPE": "User",
    "PRO_USER_ACC_TYPE": "Pro-user",
    "BROKER_ACC_TYPE": "Broker",
    "DEALER_ACC_TYPE": "Dealer"
  },
  "ka": {
    "REGULAR_USER_ACC_TYPE": "მომხმარებელი",
    "PRO_USER_ACC_TYPE": "Pro-მომხმარებელი",
    "BROKER_ACC_TYPE": "ბროკერი",
    "DEALER_ACC_TYPE": "ᲓᲘᲚᲔᲠᲘ"
  },
  "ru": {
    "REGULAR_USER_ACC_TYPE": "Пользователь",
    "PRO_USER_ACC_TYPE": "PRO пользователь",
    "BROKER_ACC_TYPE": "Брокер",
    "DEALER_ACC_TYPE": "Дилер"
  },
  "uk": {
    "REGULAR_USER_ACC_TYPE": "Користувач",
    "PRO_USER_ACC_TYPE": "PRO користувач",
    "BROKER_ACC_TYPE": "Брокер",
    "DEALER_ACC_TYPE": "Дилер"
  }
}
</i18n>
