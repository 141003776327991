import { UserCommand } from './UserCommand'
import { sdk } from 'Services/shelfNetworkSdk'

export class VerifyUserPhone extends UserCommand {
  async execute () {
    try {
      const opts = { phone_verified: true }
      await sdk.horizon.account.update(opts, this._userEntry.id)

      this._updateStoredItem({ phoneVerified: true })

      const msg = this.$t('CMD.USERS.UPDATE.PHONE_VERIFIED_MSG', { name: this._getName() })
      this._reportSuccess(msg)
    } catch (error) {
      const msg = this.$t('CMD.USERS.UPDATE.FAILURE_MSG', { name: this._getName() })
      this._reportError(error, msg)
    }
  }

  async undo () {
    try {
      const opts = { phone_verified: false }
      await sdk.horizon.account.update(opts, this._userEntry.id)

      this._updateStoredItem({ phoneVerified: false })
    } catch (error) {
      this._reportUndoError(error)
    }
  }
}
