// NOTE:
// uiUsersActions instead of regular usersActions to differ from
// userActions of entities/user

export const uiUsersActions = {
  LOAD_LIST: 'LOAD_LIST',
  LOAD_ITEM: 'LOAD_ITEM',
  LOAD_MORE: 'LOAD_MORE',
  REMOVE_ITEM: 'REMOVE_ITEM',
  UPDATE_ITEM: 'UPDATE_ITEM',
  PUSH_ITEM: 'PUSH_ITEM',
}

export const uiUsersMutations = {
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_IS_NEXT_LOADING: 'SET_IS_NEXT_LOADING',
  SET_LIST: 'SET_LIST',
  PUSH_LIST: 'PUSH_LIST',
  SET_FETCH_NEXT: 'SET_FETCH_NEXT',
  REMOVE_ITEM: 'REMOVE_ITEM',
  UPDATE_ITEM: 'UPDATE_ITEM',
}

export const uiUsersGetters = {
  IS_LOADING: 'IS_LOADING',
  IS_NEXT_LOADING: 'IS_NEXT_LOADING',
  LIST: 'LIST',
  LIST_RAW: 'LIST_RAW',
  FETCH_NEXT: 'FETCH_NEXT',
}
