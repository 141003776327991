<template>
  <div class="users-list-head users-cmn__row">
    <span class="users-list-head__cell">
      <template v-if="template !== USERS_LIST_TEMPLATES.brokers">
        {{ $t('USER_HEAD_CELL') }}
      </template>

      <template v-else>
        {{ $t('BROKER_HEAD_CELL') }}
      </template>
    </span>

    <span class="users-list-head__cell">
      {{ $t('LOCATION_HEAD_CELL') }}
    </span>

    <span class="users-list-head__cell">
      {{ $t('PHONE_HEAD_CELL') }}
    </span>

    <span class="users-list-head__cell">
      {{ $t('EMAIL_HEAD_CELL') }}
    </span>

    <span class="users-list-head__cell">
      {{ $t('CREATED_AT_HEAD_CELL') }}
    </span>
  </div>
</template>

<script>
import { USERS_LIST_TEMPLATES } from '../constants'

export default {
  name: 'users-list-head',

  props: {
    template: {
      type: String,
      default: USERS_LIST_TEMPLATES.regular,
      validator (value) {
        return Object.values(USERS_LIST_TEMPLATES).includes(value)
      },
    },
  },

  data () {
    return {
      USERS_LIST_TEMPLATES,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/users.scss";

.users-list-head {
  padding: 2em 3em 0;

  &__cell {
    @extend .users-cmn__cell;

    line-height: 1.4;
    color: $color-dark-grey;
  }
}
</style>

<i18n>
{
  "en": {
    "USER_HEAD_CELL": "USER",
    "BROKER_HEAD_CELL": "BROKER",
    "LOCATION_HEAD_CELL": "LOCATION",
    "PHONE_HEAD_CELL": "PHONE",
    "EMAIL_HEAD_CELL": "EMAIL",
    "CREATED_AT_HEAD_CELL": "CREATED AT"
  },
  "ka": {
    "USER_HEAD_CELL": "ᲛᲝᲛᲮᲛᲐᲠᲔᲑᲔᲚᲘ",
    "BROKER_HEAD_CELL": "ᲑᲠᲝᲙᲔᲠᲘ",
    "LOCATION_HEAD_CELL": "ᲚᲝᲙᲐᲪᲘᲐ",
    "PHONE_HEAD_CELL": "ᲢᲔᲚᲔᲤᲝᲜᲘ",
    "EMAIL_HEAD_CELL": "ᲔᲚ.ᲤᲝᲡᲢᲠᲐ",
    "CREATED_AT_HEAD_CELL": "ᲨᲔᲥᲛᲜᲘᲚᲘ"
  },
  "ru": {
    "USER_HEAD_CELL": "ПОЛЬЗОВАТЕЛЬ",
    "BROKER_HEAD_CELL": "БРОКЕР",
    "LOCATION_HEAD_CELL": "ЛОКАЦИЯ",
    "PHONE_HEAD_CELL": "ТЕЛЕФОН",
    "EMAIL_HEAD_CELL": "ЭЛ. ПОЧТА",
    "CREATED_AT_HEAD_CELL": "СОЗДАН"
  },
  "uk": {
    "USER_HEAD_CELL": "КОРИСТУВАЧ",
    "BROKER_HEAD_CELL": "БРОКЕР",
    "LOCATION_HEAD_CELL": "ЛОКАЦІЯ",
    "PHONE_HEAD_CELL": "ТЕЛЕФОН",
    "EMAIL_HEAD_CELL": "ЕЛ. ПОШТА",
    "CREATED_AT_HEAD_CELL": "СТВОРЕНО"
  }
}
</i18n>
