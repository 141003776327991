<template>
  <div class="user-card-name-edit">
    <template v-if="!isEditing">
      <p class="user-card-name-edit__name-str">
        {{ fullName }}

        <template v-if="isPro">
          <span class="user-card-name-edit__pro-mark">
            {{ $t('PRO_TXT') }}
          </span>
        </template>
      </p>

      <ui-button
        class="user-card-name-edit__inline-btn"
        frame="none"
        fill="none"
        @click="startEdit()"
      >
        <ui-icon
          class="user-card-name-edit__inline-btn-ico"
          icon="pen"
        />
      </ui-button>
    </template>

    <template v-else>
      <form
        class="user-card-name-edit__form"
        @submit.prevent="submit()"
        @reset.prevent="stopEdit()"
      >
        <user-card-field
          class="user-card-name-edit__field"
          field="UiText"
          maxlength="30"
          :placeholder="$t('FIRST_NAME_PH')"
          v-model="form.firstName"
          :errors="formErrors.firstName.map(jsvLocalizeError)"
          @input="formErrors.firstName = []"
        />

        <template v-if="$can($USER_CLAIMS.SHELF_AP_FEATURES)">
          <user-card-field
            class="user-card-name-edit__field"
            field="UiText"
            maxlength="30"
            :placeholder="$t('MIDDLE_NAME_PH')"
            v-model="form.middleName"
            :errors="formErrors.middleName.map(jsvLocalizeError)"
            @input="formErrors.middleName = []"
          />
        </template>

        <user-card-field
          class="user-card-name-edit__field"
          field="UiText"
          maxlength="30"
          :placeholder="$t('LAST_NAME_PH')"
          v-model="form.lastName"
          :errors="formErrors.lastName.map(jsvLocalizeError)"
          @input="formErrors.lastName = []"
        />

        <div class="user-card-name-edit__form-actions">
          <ui-button
            class="user-card-name-edit__inline-btn"
            frame="none"
            fill="none"
            type="submit"
          >
            <ui-icon
              class="user-card-name-edit__inline-btn-ico"
              icon="success"
            />
          </ui-button>

          <ui-button
            class="user-card-name-edit__inline-btn"
            frame="none"
            fill="none"
            type="reset"
          >
            <ui-icon
              class="user-card-name-edit__inline-btn-ico"
              icon="close"
            />
          </ui-button>
        </div>
      </form>
    </template>
  </div>
</template>

<script>
import UserCardField from './UserCardField'
import { UiIcon, UiButton } from '@shelf.network/ui-kit'
import { UserEntry } from 'Models/UserEntry'
import { UpdateUser } from '../../commands/UpdateUser'
import validationSchema from '../../json-schemas/update-name-validation.json'
import { jsv } from 'Utils/jsv'
import { can } from 'Utils/userHelpers'
import { USER_CLAIMS } from 'Constants/userClaims'
import { jsvLocalizeError } from 'Utils/jsvLocalizeError'
import { safeUserName } from 'Utils/safeUserName'
import formatFullName from 'Utils/formatFullName'

export default {
  name: 'user-card-name-edit',

  components: {
    UiIcon,
    UiButton,
    UserCardField,
  },

  props: {
    userEntry: {
      type: UserEntry,
      required: true,
    }
  },

  data () {
    return {
      isEditing: false,
      form: this.entryToForm(),
      formErrors: this.entryToFormErrorsDefault()
    }
  },

  computed: {
    fullName () {
      return safeUserName(formatFullName(this.userEntry))
    },

    isPro () {
      return this.userEntry.accountType === UserEntry.accountTypesEnum.business
    },
  },

  created () {
    this.$watch(
      () => [
        this.userEntry.firstName,
        this.userEntry.lastName,
        ...(can(USER_CLAIMS.SHELF_AP_FEATURES)
          ? [this.userEntry.middleName]
          : []
        ),
      ],
      () => { this.form = this.entryToForm() },
    )
  },

  methods: {
    entryToForm () {
      return {
        firstName: this.userEntry.firstName,
        lastName: this.userEntry.lastName,
        ...(can(USER_CLAIMS.SHELF_AP_FEATURES)
          ? {
            middleName: this.userEntry.middleName,
          } : {}
        )
      }
    },

    entryToFormErrorsDefault () {
      const newObjEntries = Object.entries(this.entryToForm())
        .map(([key]) => [key, []])
      return Object.fromEntries(newObjEntries)
    },

    checkFormValid () {
      if (Object.values(this.formErrors).some(el => el.length > 0)) return false

      const result = jsv.validate(validationSchema, this.form)
      if (result.isValid) return true

      Object.assign(this.formErrors, result.byField())
      return false
    },

    async submit () {
      if (!this.checkFormValid()) return

      const cmd = new UpdateUser({
        userEntry: this.userEntry,
        attributes: this.form,
      })
      await cmd.execute()
      this.stopEdit()
    },

    startEdit () {
      this.form = this.entryToForm()
      this.isEditing = true
    },

    stopEdit () {
      this.isEditing = false
    },

    jsvLocalizeError,
  }
}
</script>

<style lang="scss" scoped>
.user-card-name-edit {
  display: flex;
  align-items: center;
  line-height: 2em;

  &__name-str {
    @include respond(--font-size, 1.4em, 1.4em, 1.3em, 1.2em);

    font-size: var(--font-size);
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__pro-mark {
    color: $color-user-pro;
    margin-left: 0.2em;
  }

  &__inline-btn {
    line-height: 1;
    margin-left: 0.5em;
    justify-self: start;

    /deep/ .ui-button__button {
      padding: 0.25em;
    }

    &-ico {
      display: block;
      font-size: 1.5em;
    }
  }

  &__field {
    /deep/ .user-card-field__field-component {
      font-size: 0.9em;
    }

    /deep/ .ui-text__input {
      font-size: inherit;
    }
  }

  &__form {
    display: grid;
    align-items: start;
    width: 100%;
    margin-bottom: 1em;
    gap: 0.5em;
    grid: auto / repeat(auto-fit, minmax(auto, 14em));

    &-actions {
      display: grid;
      justify-content: start;
      align-items: center;
      grid: auto / auto-flow auto;
      height: 2.8em;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "FIRST_NAME_PH": "First name",
    "LAST_NAME_PH": "Last name",
    "MIDDLE_NAME_PH": "Middle name",
    "PRO_TXT": "PRO"
  },
  "ka": {
    "FIRST_NAME_PH": "სახელი",
    "LAST_NAME_PH": "გვარი",
    "MIDDLE_NAME_PH": "შუა სახელი",
    "PRO_TXT": "PRO"
  },
  "ru": {
    "FIRST_NAME_PH": "Имя",
    "LAST_NAME_PH": "Фамилия",
    "MIDDLE_NAME_PH": "Второе имя",
    "PRO_TXT": "PRO"
  },
  "uk": {
    "FIRST_NAME_PH": "Ім’я",
    "LAST_NAME_PH": "Прізвище",
    "MIDDLE_NAME_PH": "Друге ім’я",
    "PRO_TXT": "PRO"
  }
}
</i18n>
