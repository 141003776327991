var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"users"},[_c('page-subnav',{staticClass:"users__subnav"},[_c('ui-button',{staticClass:"users__create-btn",on:{"click":function($event){_vm.isUserCreateModalShown = true}}},[_vm._v("\n      "+_vm._s(_vm.$t('CREATE_USER_BTN'))+"\n      "),_c('ui-icon',{attrs:{"icon":"plus"}})],1),_vm._v(" "),_c('users-list-params',{staticClass:"users__subnav-action",attrs:{"value":{
        sort: _vm.$route.query.sort || '',
        location: _vm.$route.query.location || '',
      }},on:{"input":_vm.onListParamsChange}}),_vm._v(" "),_c('subnav-search',{staticClass:"users__subnav-action users__subnav-action_ml",attrs:{"value":_vm.$route.query.search},on:{"input":_vm.onSearch}}),_vm._v(" "),_c('template',{slot:"right"},[_c('router-link',{staticClass:"users__tab",attrs:{"active-class":"users__tab_active","to":{
          params: { tab: _vm.USERS_URL_PARAM_TABS.regular },
        }}},[_vm._v("\n        "+_vm._s(_vm.$t('REGULAR_TAB'))+"\n      ")]),_vm._v(" "),_c('router-link',{staticClass:"users__tab",attrs:{"active-class":"users__tab_active","to":{
          params: { tab: _vm.USERS_URL_PARAM_TABS.pro },
        }}},[_vm._v("\n        "+_vm._s(_vm.$t('PRO_TAB'))+"\n      ")]),_vm._v(" "),_c('router-link',{staticClass:"users__tab",attrs:{"active-class":"users__tab_active","to":{
          params: { tab: _vm.USERS_URL_PARAM_TABS.brokers },
        }}},[_vm._v("\n        "+_vm._s(_vm.$t('BROKERS_TAB'))+"\n      ")]),_vm._v(" "),_c('router-link',{staticClass:"users__tab",attrs:{"active-class":"users__tab_active","to":{
          params: { tab: _vm.USERS_URL_PARAM_TABS.dealers },
        }}},[_vm._v("\n        "+_vm._s(_vm.$t('DEALERS_TAB'))+"\n      ")])],1)],2),_vm._v(" "),_c('users-list',{staticClass:"users__list",attrs:{"list":_vm.listProcessed,"template":_vm.template},on:{"update-list-ask":_vm.loadList,"more-ask":_vm.loadMore}}),_vm._v(" "),_c('user-card-modal',{staticClass:"users__item-card-modal",attrs:{"users-list":_vm.listProcessed,"is-users-loading":_vm.isLoading,"user-id":_vm.$route.params.userId,"user-tab":_vm.$route.params.userTab},on:{"update:userId":function($event){return _vm.$router.push({
      params: { userId: $event },
      query: _vm.$route.query,
    })},"update:userTab":function($event){return _vm.$router.push({
      params: { userTab: $event },
      query: _vm.$route.query,
    })},"close":function($event){return _vm.$router.push({
      params: { userId: null, userTab: null },
      query: _vm.$route.query,
    })}}}),_vm._v(" "),(_vm.isUserCreateModalShown)?_c('user-create-modal',{staticClass:"users__create-modal",on:{"close":function($event){_vm.isUserCreateModalShown = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }