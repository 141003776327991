<template>
  <button
    class="op-unmake-broker user-op user-op__btn"
    @click="onClick"
  >
    <icon-combo
      class="op-unmake-broker__icon-combo user-op__btn-ico"
      icon="user"
      icon-addition="minus-bold"
    />

    <span class="user-op__btn-txt">
      <template v-if="isBroker">
        {{ $t('DEMOTE_TO_PRO_BTN') }}
      </template>

      <template v-else>
        {{ $t('DEMOTE_TO_REGULAR_BTN') }}
      </template>
    </span>
  </button>
</template>

<script>
import IconCombo from 'Common/IconCombo'
import { UserEntry } from 'Models/UserEntry'

export default {
  name: 'op-unmake-broker',

  components: {
    IconCombo,
  },

  props: {
    userEntry: {
      type: UserEntry,
      required: true,
    },
  },

  computed: {
    isBroker () {
      return this.userEntry.accountType === UserEntry.accountTypesEnum.broker
    },
  },

  methods: {
    onClick () {
      if (this.isBroker) {
        alert('TODO: demote to pro action')
      } else {
        alert('TODO: demote to regular action')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../styles/users-operations.scss";

.op-unmake-broker {
  &__icon-combo {
    --addition-color: #{$color-sys-attention};
  }
}
</style>

<i18n>
{
  "en": {
    "DEMOTE_TO_PRO_BTN": "Deactivate Broker",
    "DEMOTE_TO_REGULAR_BTN": "Deactivate PRO"
  },
  "ka": {
    "DEMOTE_TO_PRO_BTN": "ბროკერის გამორთვა",
    "DEMOTE_TO_REGULAR_BTN": "გამორთვა PRO"
  },
  "ru": {
    "DEMOTE_TO_PRO_BTN": "Деактивировать брокера",
    "DEMOTE_TO_REGULAR_BTN": "Деактивировать PRO"
  },
  "uk": {
    "DEMOTE_TO_PRO_BTN": "Дезактивувати брокера",
    "DEMOTE_TO_REGULAR_BTN": "Дезактивувати PRO"
  }
}
</i18n>
