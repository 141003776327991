<template>
  <list-grouped
    class="users-list"
    :list="list"
    :list-total-count="0"
    :is-loading="isLoading"
    :is-next-loading="isNextLoading"
    :page-size="USERS_PAGE_LIMIT"
    :load-more="loadMore"
    :reset-on-change="template"
    :head-component="UsersListHead"
    :head-component-props="{ template }"
    :item-component="UserItem"
    :item-component-props="{ template }"
    :item-skeleton-component="UserItemSkeleton"
    @update-list-ask="$emit('update-list-ask')"
  />
</template>

<script>
import UserItem from './UserItem'
import UserItemSkeleton from './UserItemSkeleton'
import UsersListHead from './UsersListHead'
import ListGrouped from 'Common/ListGrouped'
import { mapGetters, mapActions } from 'vuex'
import { uiUsersGetters, uiUsersActions } from '../store/types'
import { USERS_LIST_TEMPLATES, USERS_PAGE_LIMIT } from '../constants'
import { validateArrayOf } from 'Models/modelUtils'
import { UserEntry } from 'Models/UserEntry'

export default {
  name: 'users-list',

  components: {
    ListGrouped,
  },

  props: {
    list: {
      type: Array,
      required: true,
      validator: validateArrayOf(UserEntry),
    },

    template: {
      type: String,
      default: USERS_LIST_TEMPLATES.regular,
      validator (value) {
        return Object.values(USERS_LIST_TEMPLATES).includes(value)
      },
    },
  },

  data () {
    return {
      UserItem,
      UserItemSkeleton,
      UsersListHead,
      USERS_PAGE_LIMIT,
      storeUnsubscriber: () => { },
    }
  },

  computed: {
    ...mapGetters('ui/users', {
      isLoading: uiUsersGetters.IS_LOADING,
      isNextLoading: uiUsersGetters.IS_NEXT_LOADING,
    }),
  },

  beforeDestroy () {
    this.storeUnsubscriber()
  },

  methods: {
    ...mapActions('ui/users', {
      loadMore: uiUsersActions.LOAD_MORE,
    }),
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/users.scss";

.users-list {
  --list-padding-side: 3em;
}
</style>
