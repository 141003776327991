<template>
  <div class="user-card-field">
    <!-- TODO: to common -->

    <template v-if="label">
      <label class="user-card-field__lbl">
        {{ label }}
      </label>
    </template>

    <component
      class="user-card-field__field-component"
      :is="FIELDS[field]"
      fill="frame"
      :is-error="errors.length > 0"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <slot />

      <slot
        name="dock-left"
        slot="dock-left"
      />

      <slot
        name="dock-right"
        slot="dock-right"
      />

      <p
        class="user-card-field__error"
        slot="error"
        v-for="err of errors"
        :key="err"
      >
        {{ err }}
      </p>
    </component>
  </div>
</template>

<script>
import { UiSelect, UiText, UiPhone } from '@shelf.network/ui-kit'
import SelectLocation from 'Common/SelectLocation'
import SelectAccountType from 'Common/SelectAccountType'
import { validateArrayOfStrings } from 'Models/modelUtils'

const FIELDS = {
  UiSelect,
  UiText,
  SelectLocation,
  SelectAccountType,
  UiPhone,
}

export default {
  name: 'user-card-field',

  props: {
    label: {
      type: String,
      default: '',
    },

    field: {
      type: String,
      default: FIELDS.UiText,
      validator: val => Boolean(FIELDS[val]),
    },

    errors: {
      type: Array,
      default: () => [],
      validator: validateArrayOfStrings(),
    },
  },

  data () {
    return {
      FIELDS,
    }
  },
}
</script>

<style lang="scss" scoped>
.user-card-field {
  &__lbl {
    margin-bottom: 1em;
    display: inline-block;
  }

  &__field-component {
    &.ui-text /deep/ .ui-text__input {
      &[fill][look] {
        background-color: $color-light;
        border-color: $color-grey;

        &:focus {
          border-color: $color-ui-default;
        }
      }
    }

    &.ui-select /deep/ .ui-select__button {
      & > .ui-button__button[fill][look] {
        background-color: $color-light;
        border-color: $color-grey;
      }

      &.ui-select__button_is-open > .ui-button__button[fill][look] {
        border-color: $color-ui-default;
      }
    }

    &.ui-phone {
      /deep/ .ui-phone__input-wrp[fill][look] {
        background-color: $color-light;
        border-color: $color-grey;
      }

      /deep/ .ui-phone__country-dropdown {
        background-color: $color-light;
      }

      &.ui-phone_is-open /deep/ .ui-phone__input-wrp[fill][look] {
        border-color: $color-ui-default;
        border-bottom-color: $color-light;
      }
    }
  }
}
</style>
