import { render, staticRenderFns } from "./UserCellTimeAgo.vue?vue&type=template&id=002b486c&scoped=true&"
import script from "./UserCellTimeAgo.vue?vue&type=script&lang=js&"
export * from "./UserCellTimeAgo.vue?vue&type=script&lang=js&"
import style0 from "./UserCellTimeAgo.vue?vue&type=style&index=0&id=002b486c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "002b486c",
  null
  
)

export default component.exports